import { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { createSearchParams, Link, Navigate } from "react-router-dom";

import { FormGroup, Label, Input } from 'reactstrap';
import * as Spinner from 'react-loader-spinner'

import Header from '../../components/header'

import PhoneInput from '../../components/phoneinput'
import EmailInput from './../../components/emailinput'

import { API } from '../../utils/api';

import './ASO.css';
import StateCities from "../../utils/state-city";

function sendForm(data, navigate) {
    global.Toast.fire({
        icon: 'info',
        title: 'Enviando agendamento!',
        position: 'center'
    })

    setTimeout(() => {
        navigate('/user');
    }, 1000);

    API("exams/schedule", {
        method: "POST",
        data
    }).then(async result => {
        navigate('/user');

        if (result.result)
            global.Toast.fire({
                icon: 'success',
                title: 'Exame agendado!',
            })
        else {
            let html;
            switch (result.context) {
                case 'Company Not Actived':
                    html = <label>Empresa em análise ou suspensa!</label>
                    break;

                case 'Invalid Authorization':
                    global.Logout();
                    html = <label>Código de autorização inválido, acesse sua conta novamente!</label>
                    break;
                default:
                    html = <label>Contexto desconhecido, informe o código ao suporte: <b>{result.context}</b></label>
                    break;
            }

            global.Swal.fire({
                title: 'Oops',
                icon: 'warning',
                html,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Fechar'
            })
        }
    })
}

function maskCPF(cpf) {
    cpf = cpf.replace(/\D/g, "")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    return cpf
}

function maskRG(rg) {
    rg = rg.replace(/\D/g, "");
    rg = rg.replace(/(\d{2})(\d)/, "$1.$2")
    rg = rg.replace(/(\d{3})(\d)/, "$1.$2")
    rg = rg.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    return rg;
}

export default function ScheduleExame(props) {
    if (!global.user)
        global.user = {};

    const navigate = useNavigate();
    const [name, setName] = useState(global.user.contact);
    const [phone, setPhone] = useState(global.user.phone);
    const [email, setEmail] = useState(global.user.email);

    //Employee
    const [name_employee, setNameEmployee] = useState('');
    const [cpf, setCPF] = useState('');
    const [rg, setRG] = useState('');
    const [birth, setBirth] = useState('');

    const [exam, setExam] = useState('');


    const [aso, setASO] = useState(false);
    const [asoname, setASOName] = useState(false);


    const [processing, setProcessing] = useState(false);

    let user = localStorage.getItem("user");
    if (!user)
        return <Navigate to={{
            pathname: "/user/login",
            search: createSearchParams({ page: '/user/service/agendamento_exame' }).toString()
        }} />

    return (
        <>
            <Header icon="user-nurse-hair-long" text="Anexar ASO" url="" small={true} />
            <div className="aso_container">
                <div className='ps-3 pe-3' style={{
                    overflow: 'scroll',
                    height: 'calc(100vh - 17em)',
                    overflowX: 'hidden',
                    width: '100vw'
                }}>
                    <label className='mb-2'>
                        Dados do responsável
                    </label>

                    <div className='form d-flex row align-items-center'>
                        <FormGroup className='col-12 mb-3' floating>
                            <Input type='text' className={name ? name.length >= 3 ? 'border-success' : 'border-danger' : ''}
                                id="name"
                                disabled={processing}
                                value={name}
                                placeholder="Nome"
                                minLength={3}
                                maxLength={62}
                                onChange={event => {
                                    setName(event.target.value)
                                }} />

                            <Label for="name" className={'ms-2 ' +
                                (name ? name.length >= 3 ? 'text-success' : 'text-danger' : '')
                            }>
                                Nome
                            </Label>
                        </FormGroup>

                        <PhoneInput disabled={processing} phone={phone} setPhone={setPhone} label="Telefone" />

                        <EmailInput disabled={processing} email={email} setEmail={setEmail} label="Email de contato" />


                        <hr />

                        <label className='mb-2'>
                            Dados do colaborador
                        </label>

                        <FormGroup className='col-12 mb-3' floating>
                            <Input type='text' className={name_employee ? name_employee.length >= 3 ? 'border-success' : 'border-danger' : ''}
                                id="name"
                                value={name_employee}
                                disabled={processing}
                                placeholder="Nome"
                                minLength={3}
                                maxLength={62}
                                onChange={event => {
                                    setNameEmployee(event.target.value)
                                }} />

                            <Label for="name" className={'ms-2 ' +
                                (name_employee ? name_employee.length >= 3 ? 'text-success' : 'text-danger' : '')
                            }>
                                Nome do colaborador
                            </Label>
                        </FormGroup>

                        <FormGroup className='col-6 mb-3' floating>
                            <Input type='text' className={cpf ? cpf.length >= 10 ? 'border-success' : 'border-danger' : ''}
                                id="cpf"
                                value={cpf}
                                disabled={processing}
                                placeholder="CPF"
                                minLength={3}
                                maxLength={62}
                                onChange={event => {
                                    setCPF(maskCPF(event.target.value))
                                }} />

                            <Label for="name" className={'ms-2 ' +
                                (cpf ? cpf.length >= 3 ? 'text-success' : 'text-danger' : '')
                            }>
                                CPF do colaborador
                            </Label>
                        </FormGroup>

                        <FormGroup className='col-6 mb-3' floating>
                            <Input type='text' className={rg ? rg.length >= 9 ? 'border-success' : 'border-danger' : ''}
                                id="rg"
                                value={rg}
                                disabled={processing}
                                placeholder="RG"
                                minLength={3}
                                maxLength={62}
                                onChange={event => {
                                    setRG(maskRG(event.target.value))
                                }} />

                            <Label for="name" className={'ms-2 ' +
                                (rg ? rg.length >= 3 ? 'text-success' : 'text-danger' : '')
                            }>
                                RG do colaborador
                            </Label>
                        </FormGroup>

                        <FormGroup className='col-12 mb-3' floating>
                            <Input type='date' className={birth ? birth ? 'border-success' : 'border-danger' : ''}
                                id="birth"
                                value={birth}
                                disabled={processing}
                                placeholder="Data de nascimento"
                                min={'1916-01-01'}
                                max={new Date(new Date().setDate(new Date().getDate() - (28 * 12) * 14)).toISOString().split('T')[0]}
                                onFocus={(event) => {
                                    event.target.showPicker()
                                }}
                                onChange={event => {
                                    setBirth(event.target.value)
                                }} />

                            <Label for="birth" className={'ms-2 ' +
                                (birth ? birth ? 'text-success' : 'text-danger' : '')
                            }>
                                Data de nascimento
                            </Label>
                        </FormGroup>

                        <hr />
                        <FormGroup className='col-12 mb-3' floating>
                            <Input type='select'
                                className={['', exam ? exam ? 'border-success' : 'border-danger' : '']}
                                id="exam"
                                value={exam}
                                disabled={processing}
                                placeholder="Tipo do exame"
                                onChange={event => {
                                    setExam(event.target.value)
                                }}>
                                <option value="" selected >Selecione uma opção</option>
                                <option value="Admissional">Admissional</option>

                                <option value="Demissional">Demissional</option>

                                <option value="Periódico">Periódico</option>
                            </Input>

                            <Label for="exam" className={'ms-2 ' +
                                (exam ? exam ? 'text-success' : 'text-danger' : '')
                            }>
                                Tipo do exame
                            </Label>
                        </FormGroup>

                        <div class="rounded" style={{
                            border: 'solid 1px #e3e6e9',
                            marginLeft: '.7em',
                            width: 'calc( 100% - 1.5em)',
                            marginBottom: '1em'
                        }}>
                            <Label for="name" className={(cpf ? cpf.length >= 3 ? 'text-success' : 'text-danger' : '')}
                                style={{
                                    color: '#6f7275',
                                    marginTop: '.3em'
                                }}>
                                Anexar ASO
                            </Label>

                            <FormGroup className='mb-3' floating>
                                <input type='file' accept="image/*" className={(aso ? 'border-success' : 'border-danger') + " form-control pb-2 pt-2"}
                                    id="aso"
                                    placeholder="Anexar ASO"
                                    disabled={processing}
                                    onChange={event => {
                                        const reader = new FileReader();
                                        reader.readAsDataURL(event.target.files[0]);
                                        reader.onload = () => {
                                            setASOName(event.target.files[0].name)
                                            setASO(reader.result?.toString() || '')
                                        }
                                    }} />
                            </FormGroup>
                        </div>
                        <hr />


                        <div className='w-100 d-flex align-items-center mt-1 mb-5'>
                            <button type="button" disabled={processing} className='btn btn-success w-100 mb-5' onClick={() => {
                                let error = false;

                                if (!name)
                                    error = 'Informe o nome do responsável'
                                else if (!email)
                                    error = 'Informe o email de contato'
                                else if (!phone)
                                    error = 'Informe o telefone de contato'

                                else if (!name_employee)
                                    error = 'Informe o nome do colaborador'
                                else if (!cpf)
                                    error = 'Informe o CPF do colaborador'
                                else if (!rg)
                                    error = 'Informe o RG do colaborador'
                                else if (!birth)
                                    error = 'Informe o nascimento do colaborador'
                                else if (!exam)
                                    error = 'Informe o tipo de exame'
                                else if (!aso)
                                    error = 'Anexe o ASO'

                                if (error)
                                    return global.Swal.fire({
                                        icon: 'warning',
                                        //title: 'Oops...',
                                        html: <b>{error}</b>,
                                        confirmButtonText: 'Entendi'
                                    })
                                setProcessing(true)

                                let data = {
                                    name,
                                    email,
                                    phone,

                                    name_employee,
                                    cpf,
                                    rg,
                                    birth,
                                    exam,

                                    aso,
                                    asoname
                                }

                                API("aso", {
                                    method: "POST",
                                    data
                                }).then(async result => {
                                    if (!result)
                                        global.Swal.fire({
                                            icon: 'error',
                                            title: 'Algo deu errado',
                                            html: <label>Algo deu errado com seu envio, por favor, tente novamente mais tarde!</label>,
                                            confirmButtonText: 'Entendi'
                                        })
                                    else
                                        global.Swal.fire({
                                            icon: 'success',
                                            title: 'ASO enviado',
                                            html: <label>ASO foi enviado com sucesso!</label>,
                                            confirmButtonText: 'Fechar'
                                        })

                                    global.history.go(-1)
                                })

                            }}>
                                <label>
                                    {processing ? <Spinner.Oval
                                        height="24"
                                        width="24"
                                        color="white"
                                        ariaLabel="tail-spin-loading"
                                        radius="1"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    /> : 'Enviar'}
                                </label>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
