import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Header from './../../components/header'

import EmailInput from './../../components/emailinput'
import PhoneInput from './../../components/phoneinput'
import CNPJInput from './../../components/cnpjinput'

import './Register.css';

import { FormGroup, Label, Input } from 'reactstrap';
import { API } from './../../utils/api';


import StateCities from "./../../utils/state-city";
import axios from "axios";

function validateCNPJ(cnpj) {
    var b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
    var c = String(cnpj).replace(/[^\d]/g, '')

    if (c.length !== 14)
        return false

    if (/0{14}/.test(c))
        return false

    for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
    if (c[12] != (((n %= 11) < 2) ? 0 : 11 - n))
        return false

    for (i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
    if (c[13] != (((n %= 11) < 2) ? 0 : 11 - n))
        return false

    return true
}

function getAddress(cep, setAddress, setState, setCity, setAddress_Disabled) {
    axios({
        url: `https://viacep.com.br/ws/${cep}/json/`,
    }).then(res => {
        res = res.data;

        if (!res.logradouro) {
            setAddress_Disabled(false)

            setAddress('');
        } else {
            setAddress_Disabled(true)
            let address = `${res.logradouro}${res.complemento ? ' ' + res.complemento : ''}, ${res.bairro}`
            setAddress(address);

            setState(res.uf);
            setCity(res.localidade);

            global.Toast.close()
        }
    })
}

export default function Register(props) {
    //console.log(StateCities.getStates());

    const navigate = useNavigate();

    const [first, setFirst] = useState(true);

    const [company, setCompany] = useState('');
    const [cnpj, setCNPJ] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');

    const [esocial, setESocial] = useState(-1);

    const [cep, setCEP] = useState('');

    const [address, setAddress] = useState('');
    const [address_disabled, setAddress_Disabled] = useState(true);
    const [address_n, setAddress_N] = useState('');

    const [state, setState] = useState('BA');
    const [city, setCity] = useState('Salvador');

    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    if (first)
        axios({
            url: 'http://ip-api.com/json',
        }).then((res) => {
            res = res.data;
            setFirst(false);

            if (res.countryCode !== 'BR')
                return;

            if (!res.region)
                return;

            setFirst(false);
            setState(res.region)
            if (res.city)
                setCity(res.city)
            else
                setAddress_Disabled(false)
        })

    return (
        <>
            <Header icon="user-plus" text="Cadastro de novo usuário" url="" small={true} />
            <div className="register_container">

                <div className='ps-3 pe-3' style={{
                    overflow: 'scroll',
                    height: 'calc(100vh - 17em)',
                    overflowX: 'hidden',
                    width: '100vw'
                }}>
                    <label className='mb-2'>
                        Preencha os dados da empresa
                    </label>

                    <div className='form d-flex row align-items-center'>
                        <CNPJInput cnpj={cnpj} setCNPJ={setCNPJ} label="CNPJ"
                            onChange={(event) => {
                                if (validateCNPJ(event)) {
                                    global.Toast.fire({
                                        icon: "info",
                                        title: "Obtendo Razão Social",
                                        position: 'center'
                                    })

                                    axios({
                                        url: `https://publica.cnpj.ws/cnpj/${event.replace(/[^\d]/g, '')}`,
                                    }).then(res => {
                                        res = res.data;

                                        setCompany(res.natureza_juridica.descricao == "Empresário (Individual)" ?
                                            res.estabelecimento.nome_fantasia
                                            :
                                            res.razao_social)

                                        setCEP(res.estabelecimento.cep)

                                        setAddress_N(res.estabelecimento.numero)

                                        getAddress(res.estabelecimento.cep.replace(/\D/g, ''), setAddress, setState, setCity, setAddress_Disabled);

                                        global.Toast.close()
                                    }).catch(() => global.Toast.close())
                                }
                            }} />

                        {/*Razao social*/}
                        <FormGroup className='col-12 mb-3' floating>
                            <Input type='text' className={company ? company.length > 3 ? 'border-success' : 'border-danger' : ''}
                                id="company"
                                placeholder="Razão social"
                                value={company}
                                onChange={event => {
                                    setCompany(event.target.value)
                                }} />
                            <Label for="company" className={['ms-2', company ? company.length > 3 ? 'text-success' : 'text-danger' : '']}>Razão social</Label>
                        </FormGroup>
                        <hr />

                        {/*Contato*/}
                        <FormGroup className='col-12 mb-3' floating>
                            <Input type='text' className={name ? name.length > 3 ? 'border-success' : 'border-danger' : ''}
                                id="name"
                                value={name}
                                placeholder="Responsável pela solicitação"
                                onChange={event => {
                                    setName(event.target.value)
                                }} />

                            <Label for="name" className={['ms-2', name ? name.length > 3 ? 'text-success' : 'text-danger' : '']} > Responsável pela solicitação</Label>
                        </FormGroup>

                        <PhoneInput phone={phone} setPhone={setPhone} label="Telefone" />

                        <EmailInput email={email} setEmail={setEmail} label="Email de contato" />

                        <hr />

                        <FormGroup className='col-12 mb-3' floating>
                            <Input type='text' className={cep ? cep.length === 8 ? 'border-success' : 'border-danger' : ''}
                                id="cep"
                                value={cep}
                                placeholder="CEP"
                                minLength={8}
                                maxLength={8}
                                onChange={event => {
                                    if (event.target.value.replace(/\D/g, '').length > 8)
                                        return;

                                    setCEP(event.target.value)

                                    if (event.target.value.replace(/\D/g, '').length === 8) {

                                        getAddress(event.target.value.replace(/\D/g, ''), setAddress, setState, setCity, setAddress_Disabled);
                                    }
                                }} />

                            <Label for="cep" className={['ms-2', cep ? cep.length === 8 ? 'text-success' : 'text-danger' : '']}>CEP</Label>
                        </FormGroup>

                        <FormGroup className='col-9 mb-3' floating>
                            <Input type='text' className={address ? address.length > 3 ? 'border-success' : 'border-danger' : ''}
                                id="address"
                                value={address}
                                placeholder="Endereço"
                                disabled={address_disabled}
                                onChange={event => {
                                    setAddress(event.target.value)
                                }} />

                            <Label for="address" className={['ms-2', address ? address.length > 3 ? 'text-success' : 'text-danger' : '']}>Endereço</Label>
                        </FormGroup>

                        <FormGroup className='col-3 mb-3' floating>
                            <Input type='text' className={address_n ? address_n.length > 1 ? 'border-success' : 'border-danger' : ''}
                                id="number"
                                value={address_n}
                                placeholder="N°"
                                onChange={event => {
                                    setAddress_N(event.target.value.replace(/\D/g, ''))
                                }} />

                            <Label for="number" className={['ms-2', address_n ? address_n.length > 1 ? 'text-success' : 'text-danger' : '']}>N°</Label>
                        </FormGroup>

                        <FormGroup className="col-6 mb-3">
                            <Input
                                className="text-center p-3"
                                type="select"
                                name="select"
                                id="stateSelect"
                                value={state}
                                onChange={(event) => {
                                    setState(event.target.value)
                                    setCity(StateCities.cities[event.target.value][0]);
                                }}
                                disabled={address_disabled}>
                                {StateCities.buildStates(state)}
                            </Input>
                        </FormGroup>

                        <FormGroup className="col-6 mb-3">
                            <Input className="text-center p-3" type="select" name="select" id="citySelect"
                                value={city}
                                onChange={(event) => {
                                    setCity(event.target.value);
                                }}
                                disabled={address_disabled}>
                                {StateCities.buildCities(state)}
                            </Input>
                        </FormGroup>

                        <hr />

                        <FormGroup className="col-12 mb-3">
                            <Label for="esocial" className={['ms-2', esocial >= 0 ? 'text-success' : '']}>Empresa já fez o envio do e-Social?</Label>
                            <Input
                                className={['text-center p-3 ', esocial >= 0 ? 'border-success' : '']}
                                type="select"
                                name="esocial"
                                id="esocial"
                                value={esocial}
                                onChange={(event) => {
                                    setESocial(event.target.value)
                                }}>
                                <option value="-1" selected={true}>Selecione uma opção</option>
                                <option value="0">Não</option>
                                <option value="1">Sim</option>
                            </Input>
                        </FormGroup>
                        <hr />

                        <FormGroup className='col-12 mb-3' floating>
                            <Input type='password' className={password ? password.length >= 6 && password == password2 ? 'border-success' : 'border-danger' : ''}
                                id="password"
                                value={password}
                                placeholder="Senha para acessar a plataforma"
                                minLength={6}
                                maxLength={32}
                                onChange={event => {
                                    setPassword(event.target.value)
                                }} />

                            <Label for="password" className={['ms-2', password ? password.length >= 6 && password == password2 ? 'text-success' : 'text-danger' : '']}>Senha para acessar a plataforma</Label>
                        </FormGroup>

                        <FormGroup className='col-12 mb-3' floating>
                            <Input type='password' className={password ? password.length >= 6 && password == password2 ? 'border-success' : 'border-danger' : ''}
                                id="password2"
                                value={password2}
                                placeholder="Confirmar senha"
                                minLength={6}
                                maxLength={32}
                                onChange={event => {
                                    setPassword2(event.target.value)
                                }} />

                            <Label for="password2" className={['ms-2', password ? password.length >= 6 && password == password2 ? 'text-success' : 'text-danger' : '']}>Confirmar senha</Label>
                        </FormGroup>

                        <div className='w-100 d-flex align-items-center mt-1 mb-5'>
                            <button type="button" className='btn btn-success w-100' onClick={async () => {
                                let title = '';

                                if (!company)
                                    title = 'Informe a Razão Social';
                                else if (!cnpj)
                                    title = 'Informe o CNPJ';
                                else if (!name)
                                    title = 'Informe o Nome do responsável';
                                else if (!phone)
                                    title = 'Informe o Telefone';
                                else if (!email)
                                    title = 'Informe o Email de contato';

                                else if (esocial < 0)
                                    title = 'Informe se enviou e-Social';

                                else if (!cep)
                                    title = 'Informe o CEP';

                                else if (!address)
                                    title = 'Informe o endereço';

                                else if (!address_n)
                                    title = 'Informe o Número do endereço';

                                else if (!password)
                                    title = 'Senha está em branco!';
                                else if (!password2)
                                    title = 'Confirmação de senha em branco!';

                                else if (password !== password2)
                                    title = 'As senhas não coincidem!';
                                else if (password.length < 6)
                                    title = 'Senha muito curta!';

                                if (title)
                                    return global.Toast.fire({
                                        icon: 'info',
                                        title,
                                        position: 'center'
                                    })

                                global.Toast.fire({
                                    icon: 'info',
                                    title: 'Enviando formulário!',
                                })

                                setTimeout(() => {
                                    navigate('/user');
                                }, 1000);


                                API("user/register", {
                                    method: "POST",
                                    data: {
                                        company,
                                        cnpj,
                                        name,
                                        phone,
                                        email,
                                        esocial,
                                        cep,
                                        address,
                                        address_n,
                                        state,
                                        city,
                                        password
                                    }
                                }).then(async result => {
                                    navigate('/user/login');

                                    if (result.result) {
                                        global.Swal.fire({
                                            icon: 'success',
                                            title: 'Registrado com sucesso!',
                                            html: <label>Suas credenciais são:<br /> <b>Números do CNPJ</b><br />e<br /><b>Senha cadastrada</b></label>,
                                            confirmButtonText: "Entendi"
                                        })
                                    } else {
                                        let html;
                                        switch (result.context) {
                                            case 'Company Already Exists':
                                                html = <label>Empresa já cadastrada!</label>
                                                break;

                                            default:
                                                html = <label>Contexto desconhecido, informe o código ao suporte: <b>{result.context}</b></label>
                                                break;
                                        }

                                        global.Swal.fire({
                                            title: 'Oops',
                                            icon: 'warning',
                                            html,
                                            confirmButtonColor: '#3085d6',
                                            confirmButtonText: 'Fechar'
                                        })
                                    }
                                })
                            }}>
                                Concluir
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}