import { NavLink } from 'react-router-dom';
import './Home.css';

export default function Home() {
    return (
        <div className="home_container">
            <div>
                <img src={`https://i.imgur.com/mqmYj57.png`} className="home_header" alt="Header" />


                <NavLink to="/user/service/orcamento_esocial"
                    className="position-absolute d-flex align-items-center justify-content-center budget text-decoration-none">
                    <label className="p-3 rounded-pill bg-success text-light w-50 fw-bold">
                        Orçamento eSocial
                    </label>
                </NavLink>
            </div>

            <div className="home_icons">
                <div className="row_item">
                    <a href="tel:7139950255"
                        target="_blank" rel="noopener noreferrer" className="home_icon">
                        <img src={`https://i.imgur.com/u7b1Yvc.png`} alt="" />
                        <label>Telefone</label>
                    </a>

                    <a href="https://api.whatsapp.com/send/?phone=557133393800&text&type=phone_number&app_absent=0"
                        target="_blank" rel="noopener noreferrer" className="home_icon">
                        <img src={`https://i.imgur.com/j2riNGr.png`} alt="" />
                        <label>WhatsApp</label>
                    </a>
                </div>

                <div className="row_item">
                    <a href="https://exattaponto.com.br/"
                        target="_blank" rel="noopener noreferrer" className="home_icon">
                        <img src={`https://i.imgur.com/l4xOc5v.png`} alt="" />
                        <label>Exatta Ponto Digital</label>
                    </a>

                    <a href="https://maps.app.goo.gl/BtNh9VjSsP82GqVU7?g_st=iwb"
                        target="_blank" rel="noopener noreferrer" className="home_icon" style={{
                            marginLeft: '10px',
                            marginRight: '10px',
                        }}>
                        <img src={`https://i.imgur.com/yXX1HUL.png`} alt="" />
                        <label>Localização</label>
                    </a>

                    <a href="https://grupoexattabahia.com.br/2022/"
                        target="_blank" rel="noopener noreferrer" className="home_icon">
                        <img src={`https://i.imgur.com/l4xOc5v.png`} alt="" />
                        <label>Grupo Exatta</label>
                    </a>
                </div>

                <div className="row_item">
                    <NavLink to="/user/service/orcamento_esocial"
                        className="home_icon">
                        <img src={`https://i.imgur.com/OmwMrho.png`} alt="" />
                        <label>Orçamento e-Social</label>
                    </NavLink>

                    <NavLink to="/user/service/agendamento_exame"
                        className="home_icon">
                        <img src={`https://i.imgur.com/LzT9uu4.png`} alt="" />
                        <label>Agendamento de exames</label>
                    </NavLink>
                </div>
            </div>
        </div >
    );
}