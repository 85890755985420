import { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { createSearchParams, Link, Navigate } from "react-router-dom";

import { Collapse, FormGroup, Label, Input } from 'reactstrap';
import * as Spinner from 'react-loader-spinner'


import Header from '../../components/header'

import { API } from '../../utils/api';

import './FAQ.css';

function CollapseReact(title, content) {
    const [open, setOpen] = useState(false);
    return <div className='d-flex flex-column w-100 mb-3' style={{
        border: 'solid 2px #eaebeb',
        borderRadius: '6px',
        padding: '6px'
    }}
        onClick={() => setOpen(!open)} >
        <h6 className='mt-2 fw-normal' style={{
            //lineHeight: 0
        }}>
            <i className={`fa-light fa-chevron-${open ? 'up' : 'down'}`} style={{
                fontSize: '1em',
                paddingRight: '0.3em'
            }} /> {title}</h6>
        <Collapse isOpen={open} className='small text-secondary'>
            {content}
        </Collapse>

    </div >
}

let temp_content, temp_category;
function BuildOpenTicket(props) {
    const [bcategory, setBCategory] = useState(props.category);

    let text = "";
    if (bcategory == 0)
        text = "dúvida";
    else if (bcategory == 1)
        text = "reclamação";
    else if (bcategory == 2)
        text = "sugestão";
    else if (bcategory == 3)
        text = "solicitação";

    return <div>
        <h6 className='mb-3 text-secondary fw-bold'>
            Suporte Exatta
        </h6>

        <label className='w-100 text-start text-secondary small fw-bold mb-1'>Categoria do chamado:</label>
        <div className='w-100 d-flex mb-3 m-0 row'>
            <button className='btn btn-outline-primary btn-sm col-6' onClick={() => {
                props.setCategory(0)
                setBCategory(0)
                temp_category = 0;
            }}>Dúvida {bcategory == 0 ? <span className="badge bg-primary border-light " style={{
                borderStyle: 'solid',
                borderWidth: '1px'
            }}> </span> : <></>}</button>


            <button className='btn btn-outline-danger btn-sm col-6' onClick={() => {
                props.setCategory(1)
                setBCategory(1)
                temp_category = 1;
            }}>Reclamação {bcategory == 1 ? <span className="badge bg-danger border-light " style={{
                borderStyle: 'solid',
                borderWidth: '1px'
            }}> </span> : <></>}</button>


            <button className='btn btn-outline-success btn-sm col-6' onClick={() => {
                props.setCategory(2)
                setBCategory(2)
                temp_category = 2;
            }}>Sugestão {bcategory == 2 ? <span className="badge bg-success border-light " style={{
                borderStyle: 'solid',
                borderWidth: '1px'
            }}> </span> : <></>}</button>

            <button className='btn btn-outline-info btn-sm col-6' onClick={() => {
                props.setCategory(3)
                setBCategory(3)
                temp_category = 3;
            }}>Solicitação {bcategory == 3 ? <span className="badge bg-info border-light " style={{
                borderStyle: 'solid',
                borderWidth: '1px'
            }}> </span> : <></>}</button>
        </div>

        <textarea className="form-control shadow-none"
            style={{
                height: '10em'
            }}
            placeholder={`Escreva sua ${text} aqui...`}
            onChange={event => {
                temp_content = event.target.value;
                props.setContent(event.target.value)
            }} />
    </div>
}

function fireOpenTicket(category, setCategory, content, setContent, navigate) {
    global.Swal.fire({
        html: <BuildOpenTicket category={category} setCategory={setCategory} content={content} setContent={setContent} />,
        customClass: {
            htmlContainer: 'm-2'
        },
        confirmButtonText: <div>Abrir chamado
            <i className={`ps-2 fa-light fa-comment-plus`} style={{
                fontSize: '1em',
                paddingRight: '.3em',
            }} /></div>
    }).then((result) => {
        let category_text = temp_category;

        if (!result.isConfirmed) {
            temp_category = 0;
            temp_content = "";
            return
        }

        if (!temp_content) {
            if (temp_category == 0)
                category_text = "dúvida";
            else if (temp_category == 1)
                category_text = "reclamação";
            else if (temp_category == 2)
                category_text = "sugestão";

            global.Toast.fire({
                icon: 'error',
                title: `Informe sua ${category_text}`,
                position: 'center'
            })
            setTimeout(() => {
                fireOpenTicket(category, setCategory, content, setContent, navigate)
            }, 2000)
            return
        }

        global.Toast.fire({
            iconHtml: <Spinner.Oval
                height="24"
                width="24"
                color="green"
                ariaLabel="tail-spin-loading"
                radius="5"
                wrapperStyle={{
                    stroke: "red"
                }}
                wrapperClass=""
                visible={true}
            />,
            title: 'Abrindo chamado',
            position: 'center'
        })

        API("ticket/open", {
            method: "POST",
            data: {
                category: temp_category,
                content: temp_content
            }
        }).then(async result => {
            temp_category = 0;
            temp_content = "";

            if (result)
                global.Toast.fire({
                    icon: 'success',
                    title: 'Chamado aberto com sucesso',
                    position: 'center'
                })
        })

        navigate('/user');
    })
}

export default function FAQ(props) {
    const [category, setCategory] = useState(0);
    const [content, setContent] = useState("");

    const [tickets_mine, setTicketsMine] = useState(false);

    let first = true;

    const navigate = useNavigate();


    const [tickets, setTickets] = useState([{
        title: "Quero enviar a ficha do funcionário",
        content: <Label>Para realizar o envio, <a href="https://bit.ly/3IgPPU4" target="_blank" rel="noopener noreferrer">clique aqui</a></Label>
    },
    {
        title: "Quero enviar um funcionário para realizar os exames",
        content: <Label>Para realizar o agendamento, <Link

            to={`/user/service/agendamento_exame`}
            className="form-label">
            clique aqui
        </Link>
        </Label>
    }]);

    if (!global.user)
        global.user = {};

    let user = localStorage.getItem("user");
    if (!user)
        return <Navigate to={{
            pathname: "/user/login",
            search: createSearchParams({ page: '/user/service/faq' }).toString()
        }} />


    if (!tickets_mine && first) {
        first = false;
        API("ticket/get_list", {
            method: "GET",
        }).then(async result => {
            if (result.result) {
                setTicketsMine(Array.isArray(result.tickets) ? result.tickets : [result.tickets])
            }
        })
    }

    let tickets_mine_items = [];
    if (tickets_mine) {
        if (!tickets_mine[0].title)
            tickets_mine_items = <Label className='small text-secondary mt-1'>Você ainda não possui chamados</Label>
        else
            tickets_mine.forEach((item, index) => {
                tickets_mine_items
                    .push(
                        <Link

                            to={`/user/service/faq_chat?id=${item.idx}`}
                            className="form-label ms-2 mt-2 p-1 border rounded text-decoration-none mt-1"
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                            key={index} >
                            <i className={`${(item.status == 2) ? "text-secondary" : (item.replied ? "text-success" : "text-warning")} fa-light fa-${(item.status == 2) ? 'check' : item.replied ? 'circle-check' : 'clock-eight'} ps-1 pe-1`}></i>
                            {item.title}
                        </Link >
                    )
            })
    } else
        tickets_mine_items = <Label className='small text-secondary d-flex align-items-center mt-1'>
            <Spinner.Oval
                height="18"
                width="18"
                color="white"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
            <Label className='ps-1 mb-0'>Verificando chamados...</Label>
        </Label>


    let faqs = [];
    tickets.forEach(ticket => {
        faqs.push(CollapseReact(ticket.title, ticket.content))
    })
    return (
        <>
            <Header icon="comments-question-check" text="Central de Ajuda" url="" small={true} />
            <div className="faq_container">
                <div className='ps-3 pe-3' style={{
                    overflow: 'scroll',
                    height: 'calc(100vh - 17em)',
                    overflowX: 'hidden',
                    width: '100vw'
                }}>

                    <div className='form d-flex row align-items-center mt-3'>
                        <div>
                            <h6 className='pt-3' style={{
                                lineHeight: 0
                            }}>Meus chamados</h6>
                            <div className='d-flex flex-column'>
                                {tickets_mine_items}
                            </div>
                        </div>
                        <hr />
                        <div className='w-100 mb-3'><h6>Perguntas frequentes</h6></div>
                        <div>
                            {faqs}
                        </div>

                        <div className='mt-4 w-100 d-flex align-items-center mt-1 mb-5'>
                            <button type="button" className='btn btn-success w-100 pb-5' onClick={async () => {
                                setCategory(0);
                                fireOpenTicket(category, setCategory, content, setContent, navigate)
                            }}>
                                Abrir chamado
                                <i className={`ps-2 fa-light fa-comment-plus`} style={{
                                    fontSize: '1em',
                                    paddingRight: '.3em',
                                }} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
