import axios from "axios";

export const API = async (param, options = null, authLogin) => {
    //Check if options has data
    if (!options)
        //Set options default struct
        options = {
            method: 'GET',
        }

    let user = localStorage.getItem('user');
    if (user)
        user = JSON.parse(user);

    let auth = authLogin;
    if (!auth) {
        if (user)
            if (user.token)
                auth = user.token;
    }

    //Set options headers
    options = {
        ...options,
        timeout: options.method === "GET" ? 15000 : 60000,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            authorization: auth
        },
    }

    if (options.body)
        options.data = options.body;

    return new Promise(resolve => {
        axios({
            ...options,
            url: 'https://api.exattaweb.com.br/api/' + param,
            //url: 'http://localhost/api/' + param,
        }).then(res => {
            if (res.data.status === 'expired'){
                global.Logout()
                return;
            }

            resolve(res.data);
        }).catch(async (error) => {
            if (param.includes('auth')) {
                resolve(
                    await API("auth", {
                        method: "POST",
                        data: {
                            user: options.data.user,
                            password: options.data.password
                        }
                    })
                )
            }

            /*
            alert(
                "Oops! " +
                error.toString()
            )
            */
        })
    })
};