import { API } from './../api';

global.askLogout = async () => {
    if (global.confirm("Ao confirmar, será necessário realizar o login novamente")) {
        global.user.auth = undefined;
        global.Logout();
    }
}

export const Auth = async (props) => {
    //const navigate = useNavigate()
    return new Promise(resolve => {
        API("auth", {
            method: "POST",
            data: {
                user: props.user.replace(/\D/g,''),
                password: props.password
            }
        }).then(async user => {
            if (!user.auth) {
                resolve(false)
            } else {
                global.user = JSON.parse(atob(user.token.split('.')[1]));
                
                localStorage.setItem('user', JSON.stringify(user));


                //navigate('/user')
                resolve(true)
            }
        })
    })
};