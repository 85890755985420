import { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { createSearchParams, Link, Navigate } from "react-router-dom";

import axios from "axios";

import { Collapse, FormGroup, Label, Input } from 'reactstrap';

import Header from '../../components/header'

import PhoneInput from '../../components/phoneinput'

import CNPJInput from './../../components/cnpjinput'

import { API } from './../../utils/api';

import './Schedule_Exam.css';
import StateCities from "./../../utils/state-city";

function sendForm(data, navigate) {
    
    global.Toast.fire({
        icon: 'info',
        title: 'Enviando agendamento!',
        position: 'center'
    })

    setTimeout(() => {
        navigate('/user');
    }, 1000);

    API("exams/schedule", {
        method: "POST",
        data
    }).then(async result => {
        navigate('/user');

        if (result.result)
            global.Toast.fire({
                icon: 'success',
                title: 'Exame agendado!',
            })
        else {
            let html;
            switch (result.context) {
                case 'Company Not Actived':
                    html = <label>Empresa em análise ou suspensa!</label>
                    break;

                case 'Invalid Authorization':
                    global.Logout();
                    html = <label>Código de autorização inválido, acesse sua conta novamente!</label>
                    break;
                default:
                    html = <label>Contexto desconhecido, informe o código ao suporte: <b>{result.context}</b></label>
                    break;
            }

            global.Swal.fire({
                title: 'Oops',
                icon: 'warning',
                html,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Fechar'
            })
        }
    })
}

function validateCNPJ(cnpj) {
    var b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
    var c = String(cnpj).replace(/[^\d]/g, '')

    if (c.length !== 14)
        return false

    if (/0{14}/.test(c))
        return false

    for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
    if (c[12] != (((n %= 11) < 2) ? 0 : 11 - n))
        return false

    for (i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
    if (c[13] != (((n %= 11) < 2) ? 0 : 11 - n))
        return false

    return true
}

function maskCPF(cpf) {
    cpf = cpf.replace(/\D/g, "")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    return cpf
}

function maskRG(rg) {
    rg = rg.replace(/\D/g, "");
    rg = rg.replace(/(\d{2})(\d)/, "$1.$2")
    rg = rg.replace(/(\d{3})(\d)/, "$1.$2")
    rg = rg.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    return rg;
}

function CollapseReact(props) {
    const [open, setOpen] = useState(false);
    return <div className='d-flex flex-column w-100 mb-3' style={{
        border: 'solid 2px #eaebeb',
        borderRadius: '6px',
        padding: '6px'
    }}>

        <h6 className='mt-2 fw-normal' onClick={() => setOpen(!open)}>
            <i className={`fa-light fa-chevron-${open ? 'up' : 'down'}`} style={{
                fontSize: '1em',
                paddingRight: '0.3em'
            }} /> {props.title}
        </h6>
        <Collapse isOpen={open} className='small text-secondary'>
            {props.content}
        </Collapse>

    </div >
}

export default function ScheduleExame(props) {
    if (!global.user)
        global.user = {};

    const navigate = useNavigate();

    const [company, setCompany] = useState(global.user.name || '');
    const [cnpj, setCNPJ] = useState(global.user.cnpj || '');

    const [name, setName] = useState('');
    const [cpf, setCPF] = useState('');
    const [rg, setRG] = useState('');
    const [birth, setBirth] = useState('');
    const [office, setOffice] = useState('');
    const [phone, setPhone] = useState('');

    const [hired, setHired] = useState('');
    const [exam, setExam] = useState('');

    const [clinic, setClinic] = useState("Rua Senador Teotônio Vilela n 190 Cidadela  Edf Convention Center Sala 306 - Fone 71 3995-0255");

    const [exam_date, setExamDate] = useState('');

    const [state, setState] = useState(global.user.state || "BA");

    const [city, setCity] = useState(
        global.user.state ?
            StateCities.getCity(state, global.user.city)
            :
            StateCities.getStateCapital(state));

    const [authorized, setAuthorized] = useState(0);

    let user = localStorage.getItem("user");
    if (!user)
        return <Navigate to={{
            pathname: "/user/login",
            search: createSearchParams({ page: '/user/service/agendamento_exame' }).toString()
        }} />

    return (
        <>
            <Header icon="user-nurse-hair-long" text="Agendamento de exame" url="" small={true} />
            <div className="schedule_exam_container">
                <div className='ps-3 pe-3 pt-3' style={{
                    overflow: 'scroll',
                    height: 'calc(100vh - 17em)',
                    overflowX: 'hidden',
                    width: '100vw'
                }}>

                    <CollapseReact title='Dados da empresa' content={
                        <FormGroup className='col-12 mb-3' floating>

                            {/*CNPJ*/}
                            <CNPJInput cnpj={cnpj} setCNPJ={setCNPJ} onChange={async (event) => {
                                if (validateCNPJ(event)) {
                                    if (global.cnpj_data) {
                                        if (global.cnpj_data.estabelecimento.cnpj != event.replace(/[^\d]/g, '')) {
                                            global.cnpj_data = (await axios({
                                                url: `https://publica.cnpj.ws/cnpj/${event.replace(/[^\d]/g, '')}`,
                                            })).data
                                        }
                                    } else {
                                        global.cnpj_data = (await axios({
                                            url: `https://publica.cnpj.ws/cnpj/${event.replace(/[^\d]/g, '')}`,
                                        })).data
                                    }

                                    setCompany(global.cnpj_data.natureza_juridica.descricao == "Empresário (Individual)" ?
                                        global.cnpj_data.estabelecimento.nome_fantasia
                                        :
                                        global.cnpj_data.razao_social)
                                }
                            }} />

                            {/*Razão social*/}
                            <FormGroup className='col-12 mb-3' floating>
                                <Input type='text' className={company ? company.length > 3 ? 'border-success' : 'border-danger' : ''}
                                    id="name"
                                    placeholder="Razão social"
                                    value={company}
                                    disabled={true} onChange={event => {
                                        setCompany(event.target.value)
                                    }} />
                                <Label for="company"
                                    className={'ms-2 ' + (company ? company.length > 3 ? 'text-success' : 'text-danger' : '')}>
                                    Razão social
                                </Label>
                            </FormGroup>

                        </FormGroup>
                    } />

                    <label className='mb-2'>
                        Preencha os dados do colaborador
                    </label>

                    <div className='form d-flex row align-items-center'>
                        <FormGroup className='col-12 mb-3' floating>
                            <Input type='text' className={name ? name.length >= 3 ? 'border-success' : 'border-danger' : ''}
                                id="name"
                                value={name}
                                placeholder="Nome do colaborador"
                                minLength={3}
                                maxLength={62}
                                onChange={event => {
                                    setName(event.target.value)
                                }} />

                            <Label for="name" className={'ms-2 ' +
                                (name ? name.length >= 3 ? 'text-success' : 'text-danger' : '')
                            }>
                                Nome do colaborador
                            </Label>
                        </FormGroup>

                        <FormGroup className='col-6 mb-3' floating>
                            <Input type='text' className={cpf ? cpf.length >= 10 ? 'border-success' : 'border-danger' : ''}
                                id="cpf"
                                value={cpf}
                                placeholder="CPF"
                                minLength={3}
                                maxLength={62}
                                onChange={event => {
                                    setCPF(maskCPF(event.target.value))
                                }} />

                            <Label for="name" className={'ms-2 ' +
                                (cpf ? cpf.length >= 3 ? 'text-success' : 'text-danger' : '')
                            }>
                                CPF do colaborador
                            </Label>
                        </FormGroup>

                        <FormGroup className='col-6 mb-3' floating>
                            <Input type='text' className={rg ? rg.length >= 9 ? 'border-success' : 'border-danger' : ''}
                                id="rg"
                                value={rg}
                                placeholder="RG"
                                minLength={3}
                                maxLength={62}
                                onChange={event => {
                                    setRG(maskRG(event.target.value))
                                }} />

                            <Label for="name" className={'ms-2 ' +
                                (rg ? rg.length >= 3 ? 'text-success' : 'text-danger' : '')
                            }>
                                RG do colaborador
                            </Label>
                        </FormGroup>

                        <FormGroup className='col-12 mb-3' floating>
                            <Input type='date' className={birth ? birth ? 'border-success' : 'border-danger' : ''}
                                id="birth"
                                value={birth}
                                placeholder="Data de nascimento"
                                min={'1916-01-01'}
                                max={new Date(new Date().setDate(new Date().getDate() - (28 * 12) * 14)).toISOString().split('T')[0]}
                                onFocus={(event) => {
                                    event.target.showPicker()
                                }}
                                onChange={event => {
                                    setBirth(event.target.value)
                                }} />

                            <Label for="birth" className={'ms-2 ' +
                                (birth ? birth ? 'text-success' : 'text-danger' : '')
                            }>
                                Data de nascimento
                            </Label>
                        </FormGroup>

                        <FormGroup className='col-12 mb-3' floating>
                            <Input type='text' className={office ? office.length >= 3 ? 'border-success' : 'border-danger' : ''}
                                id="office"
                                value={office}
                                placeholder="Função"
                                minLength={3}
                                maxLength={62}
                                onChange={event => {
                                    setOffice(event.target.value)
                                }} />

                            <Label for="office" className={'ms-2 ' +
                                (office ? office.length >= 3 ? 'text-success' : 'text-danger' : '')
                            }>
                                Função
                            </Label>
                        </FormGroup>

                        <PhoneInput phone={phone} setPhone={setPhone} label="Telefone" />

                        <FormGroup className='col-12 mb-3' floating>
                            <Input type='date' className={hired ? hired ? 'border-success' : 'border-danger' : ''}
                                id="hired"
                                value={hired}
                                placeholder="Data de admissão"
                                min={'1976-01-01'}
                                max={new Date(new Date().setDate(new Date().getDate() + 30)).toISOString().split('T')[0]}
                                onFocus={(event) => {
                                    event.target.showPicker()
                                }}
                                onChange={event => {
                                    setHired(event.target.value)
                                }} />

                            <Label for="hired" className={'ms-2 ' +
                                (hired ? hired ? 'text-success' : 'text-danger' : '')
                            }>
                                Data de admissão
                            </Label>
                        </FormGroup>

                        <hr />

                        <FormGroup className='col-12 mb-3' floating>
                            <Input type='select'
                                className={['', exam ? exam ? 'border-success' : 'border-danger' : '']}
                                id="exam"
                                value={exam}
                                placeholder="Tipo do exame"
                                onChange={event => {
                                    setExam(event.target.value)
                                }}>
                                <option value="" selected >Selecione uma opção</option>
                                <option value="Admissional">Admissional</option>

                                <option value="Demissional">Demissional</option>

                                <option value="Homologação de atestado">Homologação de atestado</option>

                                <option value="Mudança de função">Mudança de função</option>

                                <option value="Periódico e complementar">Periódico e complementar</option>

                                <option value="Retorno ao trabalho">Retorno ao trabalho</option>
                            </Input>

                            <Label for="exam" className={'ms-2 ' +
                                (exam ? exam ? 'text-success' : 'text-danger' : '')
                            }>
                                Tipo do exame
                            </Label>
                        </FormGroup>

                        <FormGroup className="col-6 mb-3" floating>
                            <Input
                                className={['text-center', state ? state ? 'border-success' : 'border-danger' : '']}
                                type="select"
                                name="state"
                                id="state"
                                value={state}
                                onChange={(event) => {
                                    setState(event.target.value)
                                    setCity(StateCities.cities[event.target.value][0]);
                                }}>
                                {StateCities.buildStates(state)}
                            </Input>

                            <Label for="state" className={'ms-2 ' +
                                (state ? state ? 'text-success' : 'text-danger' : '')
                            }>
                                Estado
                            </Label>
                        </FormGroup>

                        <FormGroup className="col-6 mb-3" floating>
                            <Input
                                className={['text-center', city ? city ? 'border-success' : 'border-danger' : '']}
                                type="select" name="select" id="citySelect"
                                value={city}
                                onChange={(event) => {
                                    setCity(event.target.value);
                                }}>
                                {StateCities.buildCities(state)}
                            </Input>

                            <Label for="state" className={'ms-2 ' +
                                (city ? city ? 'text-success' : 'text-danger' : '')
                            }>
                                Cidade
                            </Label>
                        </FormGroup>

                        <FormGroup className='col-12 mb-3' floating>
                            <Input type='select'
                                className={['', clinic ? clinic ? 'border-success' : 'border-danger' : '']}
                                id="exam"
                                value={clinic}
                                placeholder="Unidade para exame"
                                onChange={event => {
                                    setClinic(event.target.value)
                                }}>
                                
                                <option
                                    value="Rua João das Botas nº 14 Centro Médico João das Botas, CANELA, Depois do COT, próximo ao Campo Grande Tel: 71 3339 - 3800"
                                    selected>SSA - RUA JOÃO DAS BOTAS</option>
                                <option
                                    value="Rua Senador Teotônio Vilela n 190 Cidadela  Edf Convention Center Sala 306 - Fone 71 3995-0255"
                                >SSA - CIDADELA</option>
                                {/*
                                      <option
                                        value="Avenida Octávio Mangabeira n 6929, Multishop Boca do Rio - Fone 71 3339-3800"
                                    >SSA - BOCA DO RIO</option>
                                */}
                                
                                {/*
                                    <option
                                        value="Avenida Getúlio Vargas nº741, Centro Médico de Feira Sala 121 Tel: 75 3024 - 0644"
                                    >FEIRA DE SANTANA</option>
                                */}

                                <option
                                    value="Rua Eixo Urbano Central nº 7, 1º Andar sala 108 - Clínica SerMais 71 3040 - 0107,  71 3995-0255"
                                >CAMAÇARI</option>
                            </Input>

                            <Label for="clinic" className={'ms-2 ' +
                                (clinic ? clinic ? 'text-success' : 'text-danger' : '')
                            }>
                                Unidade para exame
                            </Label>
                        </FormGroup>

                        <FormGroup className='col-12 mb-3' floating>
                            <Input type='date' className={exam_date ? exam_date ? 'border-success' : 'border-danger' : ''}
                                id="exam_date"
                                value={exam_date}
                                placeholder="Data do exame"
                                min={new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0]}
                                max={new Date(new Date().setDate(new Date().getDate() + 18)).toISOString().split('T')[0]}
                                onFocus={(event) => {
                                    event.target.showPicker()
                                }}
                                onChange={event => {
                                    setExamDate(event.target.value)
                                }} />

                            <Label for="exam_date" className={'ms-2 ' +
                                (exam_date ? exam_date ? 'text-success' : 'text-danger' : '')
                            }>
                                Data do exame
                            </Label>
                        </FormGroup>

                        <hr />

                        <FormGroup className='col-12 mb-3' floating>
                            <Input type='select'
                                className={['', authorized ? authorized ? 'border-success' : 'border-danger' : '']}
                                id="exam"
                                value={authorized}
                                placeholder="Unidade para exame"
                                onChange={event => {
                                    setAuthorized(event.target.value)
                                }}>
                                <option value="" selected>Selecione uma opção</option>
                                <option value="Sim">Sim</option>

                                <option value="Não">Não</option>
                            </Input>

                            <Label for="clinic" className={'ms-2 ' +
                                (authorized ? authorized ? 'text-success' : 'text-danger' : '')
                            }>
                                Autorizo conforme PCMSO
                            </Label>
                        </FormGroup>

                        <div className='w-100 d-flex align-items-center mt-1 mb-5'>
                            <button type="button" className='btn btn-success w-100 mb-5' onClick={() => {
                                let process = true;
                                let error_field = "";

                                if (!validateCNPJ(cnpj))
                                    error_field = "CNPJ inválido"
                                else if (name === '')
                                    error_field = "Nome do colaborador"
                                else if (cpf === '')
                                    error_field = "CPF do colaborador"
                                else if (rg === '')
                                    error_field = "RG do colaborador"
                                else if (birth === '')
                                    error_field = "Nascimento do colaborador"
                                else if (office === '')
                                    error_field = "Função"
                                else if (phone === '')
                                    error_field = "Telefone do colaborador"
                                else if (hired === '')
                                    error_field = "Data de admissão"
                                else if (exam === '')
                                    error_field = "Tipo de exame"
                                else if (clinic === '')
                                    error_field = "Unidade para exame"
                                else if (exam_date === '')
                                    error_field = "Data do exame"
                                else if (state === '')
                                    error_field = "Estado"
                                else if (city === '')
                                    error_field = "Cidade"
                                else if (authorized === '')
                                    error_field = "PCMSO"

                                if (error_field)
                                    process = false;

                                if (process)
                                    sendForm({
                                        cnpj,
                                        company,

                                        name,
                                        cpf,
                                        rg,
                                        birth,
                                        office,
                                        phone,
                                        hired,
                                        exam,
                                        clinic,
                                        exam_date,
                                        state,
                                        city,
                                        authorized
                                    }, navigate);
                                else
                                    global.Toast.fire({
                                        icon: 'error',
                                        html: <label>Preencha: <b>{error_field}</b></label>,
                                        position: 'center'
                                    })

                            }}>
                                Concluir
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
