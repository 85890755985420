import { useState } from 'react'
import { useNavigate } from "react-router-dom";

import { FormGroup, Label, Input } from 'reactstrap';
import * as Spinner from 'react-loader-spinner'

import axios from "axios";

import Header from './../../components/header'

import EmailInput from './../../components/emailinput'
import PhoneInput from './../../components/phoneinput'
import CNPJInput from './../../components/cnpjinput'

import { API } from './../../utils/api';

import './Budget.css';

function validateCNPJ(cnpj) {
    var b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
    var c = String(cnpj).replace(/[^\d]/g, '')

    if (c.length !== 14)
        return false

    if (/0{14}/.test(c))
        return false

    for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
    if (c[12] != (((n %= 11) < 2) ? 0 : 11 - n))
        return false

    for (i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
    if (c[13] != (((n %= 11) < 2) ? 0 : 11 - n))
        return false

    return true
}


export default function Budget() {
    const navigate = useNavigate();

    if (!global.user) {
        global.user = ''
        if (global.cnpj_data) {
            global.user = {
                name: global.cnpj_data.natureza_juridica.descricao == "Empresário (Individual)" ?
                    global.cnpj_data.estabelecimento.nome_fantasia
                    :
                    global.cnpj_data.razao_social,
                cnpj: global.cnpj_data.estabelecimento.cnpj,
                email: global.cnpj_data.estabelecimento.email,
                phone: global.cnpj_data.estabelecimento.ddd1 + global.cnpj_data.estabelecimento.telefone1,
                cep: global.cnpj_data.estabelecimento.cep,
                address: `${global.cnpj_data.estabelecimento.tipo_logradouro} ${global.cnpj_data.estabelecimento.logradouro},  ${global.cnpj_data.estabelecimento.bairro}`,
                address_n: global.cnpj_data.estabelecimento.numero,
            }
        }
    }


    const [processing, setProcessing] = useState(false);

    const [name, setName] = useState('');
    const [company, setCompany] = useState(global.user.name || '');
    const [cnpj, setCNPJ] = useState(global.user.cnpj || '');
    const [email, setEmail] = useState(global.user.email || '');
    const [phone, setPhone] = useState(global.user.phone || '');
    const [employees, setEmployees] = useState(global.user.employees || 1);
    const [services, setServices] = useState([]);

    const [cep, setCEP] = useState(global.user.cep || '');
    const [address, setAddress] = useState(global.user.address || '');
    const [address_n, setAddress_N] = useState(global.user.address_n || '');


    return (<>
        <Header icon="user-plus" text="Orçamento eSocial" url="" small={true} bg="white" />
        <div className="budget_container">
            <div className='ps-3 pe-3 pt-2' style={{
                overflow: 'scroll',
                height: 'calc(100vh - 17em)',
                overflowX: 'hidden',
                width: '100vw'
            }}>
                <div className='form d-flex row align-items-center'>
                    <Label className='text-secondary'><b>Dados da empresa</b></Label>
                    {/*CNPJ*/}
                    <CNPJInput cnpj={cnpj} setCNPJ={setCNPJ} disabled={processing} onChange={async (event) => {
                        if (validateCNPJ(event)) {
                            if (global.cnpj_data) {
                                if (global.cnpj_data.estabelecimento.cnpj != event.replace(/[^\d]/g, '')) {
                                    global.cnpj_data = (await axios({
                                        url: `https://publica.cnpj.ws/cnpj/${event.replace(/[^\d]/g, '')}`,
                                    })).data
                                }
                            } else {
                                global.cnpj_data = (await axios({
                                    url: `https://publica.cnpj.ws/cnpj/${event.replace(/[^\d]/g, '')}`,
                                })).data
                            }

                            setCompany(global.cnpj_data.natureza_juridica.descricao == "Empresário (Individual)" ?
                                global.cnpj_data.estabelecimento.nome_fantasia
                                :
                                global.cnpj_data.razao_social)

                            setAddress(`${global.cnpj_data.estabelecimento.tipo_logradouro} ${global.cnpj_data.estabelecimento.logradouro},  ${global.cnpj_data.estabelecimento.bairro}`)
                            setAddress_N(global.cnpj_data.estabelecimento.numero)

                            //setEmail(global.cnpj_data.estabelecimento.email)

                            //setPhone(global.cnpj_data.estabelecimento.ddd1 + global.cnpj_data.estabelecimento.telefone1)

                            setCEP(global.cnpj_data.estabelecimento.cep)
                        }
                    }
                    } />

                    {/*Razão social*/}
                    <FormGroup className='col-12 mb-3' floating>
                        <Input type='text' disabled={processing} className={company ? company.length > 3 ? 'border-success' : 'border-danger' : ''}
                            id="name"
                            placeholder="Razão social"
                            value={company}
                            onChange={event => {
                                setCompany(event.target.value)
                            }} />
                        <Label for="company"
                            className={'ms-2 ' + (company ? company.length > 3 ? 'text-success' : 'text-danger' : '')}>
                            Razão social
                        </Label>
                    </FormGroup>

                    <hr />
                    <Label className='text-secondary'><b>Informações de contato</b></Label>
                    {/*Nome do responsavel*/}
                    <FormGroup className='col-12 mb-3' floating>
                        <Input type='text' disabled={processing} className={name ? name.length > 3 ? 'border-success' : 'border-danger' : ''}
                            id="name"
                            placeholder="Nome do Responsável"
                            value={name}
                            onChange={event => {
                                setName(event.target.value)
                            }} />
                        <Label for="company"
                            className={'ms-2 ' + (name ? name.length > 3 ? 'text-success' : 'text-danger' : '')}>
                            Nome do Responsável
                        </Label>
                    </FormGroup>

                    {/*Email*/}
                    <EmailInput email={email} setEmail={setEmail} disabled={processing} />

                    {/*Telefone*/}
                    <PhoneInput phone={phone} setPhone={setPhone} disabled={processing} />

                    <hr />
                    <Label className='text-secondary'><b>Proposta eSocial</b></Label>



                    {/*Funcionários*/}
                    <FormGroup className='col-12 mb-3' floating>
                        <Input type='text'
                            className={employees ? employees > 0 ? 'border-success' : 'border-danger' : ''}
                            maxLength={11}
                            id="name"
                            placeholder="Quantidade de funcionários"
                            disabled={processing}
                            value={employees}
                            onChange={event => {
                                setEmployees(event.target.value.replace(/[^\d]/g, ''))
                            }} />
                        <Label for="company"
                            className={'ms-2 ' + (employees ? employees > 0 ? 'text-success' : 'text-danger' : '')}>
                            Quantidade de funcionários
                        </Label>
                    </FormGroup>

                    {/*Serviço*/}
                    <FormGroup className='col-12 mb-3' floating>
                        <div
                            className={"border d-flex flex-column rounded ps-2 " + (services ? services.length > 0 ? 'border-success' : 'border-danger' : '')}
                            id="services" >

                            <Label for="services"
                                className={' ' + (services ? services.length > 0 ? 'text-success' : 'text-danger' : '')}>
                                Selecione os serviços desejados
                            </Label>

                            <div>
                                <Input
                                    type="checkbox"
                                    id="cb-1"
                                    disabled={processing}
                                    onChange={(event) => {
                                        let service_text = "Orçamento eSocial";

                                        if (event.target.checked)
                                            setServices([...services, service_text]);
                                        else
                                            setServices([...services.filter(service => service != service_text)]);
                                    }}
                                />
                                <Label className='ps-1'>Orçamento eSocial</Label>
                            </div>

                            <div>
                                <Input
                                    type="checkbox"
                                    id="cb-1"
                                    disabled={processing}
                                    onChange={(event) => {
                                        let service_text = "PGR";

                                        if (event.target.checked)
                                            setServices([...services, service_text]);
                                        else
                                            setServices([...services.filter(service => service != service_text)]);
                                    }} />
                                <Label className='ps-1'>PGR</Label>
                            </div>

                            <div>
                                <Input
                                    type="checkbox"
                                    id="cb-1"
                                    disabled={processing}
                                    onChange={(event) => {
                                        let service_text = "PCMSO";

                                        if (event.target.checked)
                                            setServices([...services, service_text]);
                                        else
                                            setServices([...services.filter(service => service != service_text)]);
                                    }} />
                                <Label className='ps-1'>PCMSO</Label>
                            </div>

                            <div>
                                <Input
                                    type="checkbox"
                                    id="cb-1"
                                    disabled={processing}
                                    onChange={(event) => {
                                        let service_text = "LTCAT";

                                        if (event.target.checked)
                                            setServices([...services, service_text]);
                                        else
                                            setServices([...services.filter(service => service != service_text)]);
                                    }} />
                                <Label className='ps-1'>LTCAT</Label>
                            </div>

                            <div>
                                <Input
                                    type="checkbox"
                                    id="cb-1"
                                    disabled={processing}
                                    onChange={(event) => {
                                        let service_text = "Treinamento de NRs";

                                        if (event.target.checked)
                                            setServices([...services, service_text]);
                                        else
                                            setServices([...services.filter(service => service != service_text)]);
                                    }} />
                                <Label className='ps-1'>Treinamento NRs</Label>
                            </div>

                            <div>
                                <Input
                                    type="checkbox"
                                    id="cb-1"
                                    disabled={processing}
                                    onChange={(event) => {
                                        let service_text = "Exames Médicos Ocupacionais";

                                        if (event.target.checked)
                                            setServices([...services, service_text]);
                                        else
                                            setServices([...services.filter(service => service != service_text)]);
                                    }} />
                                <Label className='ps-1'>Exames Médicos Ocupacionais</Label>
                            </div>

                        </div>
                    </FormGroup>

                    <div className='w-100 d-flex align-items-center mt-1 mb-5'>
                        <button type="button" disabled={processing} className='btn btn-success w-100 mb-5 text-center' onClick={() => {
                            let error = false;
                            if (!cnpj)
                                error = 'Informe o CNPJ'
                            else if (!company)
                                error = 'Informe a Razão Social'
                            else if (!name)
                                error = 'Informe o nome do responsável'
                            else if (!email)
                                error = 'Informe o email de contato'
                            else if (!phone)
                                error = 'Informe o telefone de contato'
                            else if (!employees)
                                error = 'Informe a quantidade de funionários'
                            else if (services.length == 0)
                                error = 'Selecione os serviços desejados'

                            if (error)
                                return global.Swal.fire({
                                    icon: 'warning',
                                    //title: 'Oops...',
                                    html: <b>{error}</b>,
                                    confirmButtonText: 'Entendi'
                                })
                            setProcessing(true)

                            let data = {
                                cnpj,
                                company,
                                name,
                                email,
                                phone,
                                employees,
                                services,
                                cep,
                                address,
                                address_n
                            }

                            API("budget", {
                                method: "POST",
                                data
                            }).then(async result => {
                                if (!result)
                                    global.Swal.fire({
                                        icon: 'error',
                                        title: 'Algo deu errado',
                                        html: <label>Algo deu errado com seu envio, por favor, tente novamente mais tarde!</label>,
                                        confirmButtonText: 'Entendi'
                                    })
                                else
                                    global.Swal.fire({
                                        icon: 'success',
                                        title: 'Orçamento enviado',
                                        html: <label>Seu orçamento foi enviado com sucesso, nossa equipe irá te contactar em breve!</label>,
                                        confirmButtonText: 'Fechar'
                                    })

                                global.history.go(-1)
                            }).catch(e => {
                                global.Swal.fire({
                                    icon: 'error',
                                    title: 'Algo deu errado',
                                    html: <label>Algo deu errado com seu envio, por favor, tente novamente mais tarde!</label>,
                                    confirmButtonText: 'Entendi'
                                })
                                global.history.go(-1)
                            })
                            //navigate('/user');
                        }}>
                            <label>
                                {processing ? <Spinner.Oval
                                    height="24"
                                    width="24"
                                    color="white"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                /> : 'Enviar'}
                            </label>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>)
}