import { useState } from 'react'
import { FormGroup, Input } from 'reactstrap';

import Header from '../../components/header'
import ListExams from '../../components/listexams'


export default function ResultExame(props) {
    const [search, setSearch] = useState('');

    let exams = [
        {
            employee: 'Luis Sergio Pinto de Oliveira',
            exam: 'Admissional',
            exams: [
                'Audiometira', 'Avaliação Psicológica', 'Eletrocardiograma', 'Raio-X'
            ],
            date: '10/11/2022',
            status: 1
        },
        {
            employee: 'Rafael Davidson Nonato Fahel Pita',
            exam: 'Periódico',
            exams: [
            ],
            date: '11/11/2022',
            status: 1
        },
        {
            employee: 'Israel Nonato da Silva',
            exam: 'Demissional',
            exams: [
                'Audiometira', 'Avaliação Psicológica', 'Eletrocardiograma', 'Raio-X'
            ],
            date: '13/12/2022',
            status: 1
        },
    ]

    let search_result = [];

    if (search === '')
        search_result = exams;
    else
        search_result = exams.filter(exam => exam.employee.toLowerCase().includes(search.toLowerCase()));

    return (
        <div className='listexams_container'>
            <Header icon="vial-circle-check" text="Resultado de exames" small={true} />
            <div>

                <FormGroup className="w-100 mt-3 mb-3">
                    <Input
                        placeholder='Digite aqui para pesquisar funcionário'
                        className="text-center"
                        type="text"
                        name="text"
                        id="stateSearch"
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                    >
                    </Input>
                </FormGroup>
                <ListExams exams={search_result} />
            </div>
        </div>
    )
}