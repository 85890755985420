import { useState } from 'react';
import Header from '../../components/header'
import ListExams from '../../components/listexams'

import { API } from './../../utils/api';

export default function ListExame(props) {
    const [first, setFirst] = useState(true);
    const [exams, setExams] = useState(false);
    const [client_exams, setClientExams] = useState([]);

    if (first) {
        setFirst(false);

        global.Toast.fire({
            icon: 'info',
            title: 'Baixando lista'
        });

        API("exams/scheduled", {
            method: "GET",
        }).then(async result => {
            global.Toast.close();

            if (!result.result)
                return;

            let buffer_exams = [];
            result.exams.forEach(exam => {
                let json_data = JSON.parse(exam.exam_data);

                buffer_exams.push({
                    employee: json_data.name,
                    exam: json_data.exam,
                    date: new Date(exam.date).toLocaleString().split(' ')[0],
                    status: 0
                })
            })

            console.log(buffer_exams, 'xxxxxx')

            setClientExams(buffer_exams);
        })
    }

    console.log(client_exams)

    return (
        <div className='listexams_container'>
            <Header icon="prescription-bottle-medical" text="Lista de exames" small={true} />
            <div className='mb-1'></div>
            <ListExams exams={client_exams} />
        </div>
    )
}