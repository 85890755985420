import { useNavigate, useLocation, Link } from "react-router-dom";

import { FormGroup, Label, Input } from 'reactstrap';

import './Header.css';

export default function Header(props) {
    const navigate = useNavigate();
    const location = useLocation();

    let SelectRegion = <></>;
    if (!props.small)
        SelectRegion = <FormGroup className="w-100 mt-2" style={{
            zIndex: 2,
        }}>
            <Label className="position-absolute pt-1 ps-2 text-light fw-bold" for="regionSelect" style={{
                pointerEvents: 'none'
            }}>Selecione sua região:</Label>
            <Input className="text-end d-flex justify-content-end" type="select" name="select" id="regionSelect">
                <option value="BA">BA</option>
            </Input>
        </FormGroup>

    let logout;

    if (localStorage.getItem("user"))
        logout = <Link
            className="position-absolute d-flex flex-row align-items-center justify-content-center justify-items-center p-2 text-decoration-none"
            style={{
                zIndex: 1,
                color: 'white',
                top: '2.2em',
                right: 1
            }}
            to={"/user/profile"}>
            <i className={`fa-light fa-gear`} style={{
                fontSize: '1.3em',
                paddingRight: '.3em',
            }} />
        </Link>

    let goBack;
    if (location.pathname !== '/user')
        goBack = <label className="position-absolute d-flex flex-row align-items-center justify-content-center justify-items-center p-2" style={{
            zIndex: 1,
            color: 'white',
            top: '2.2em',
            left: 1
        }}
            onClick={() => {
                global.history.go(-1)
            }}>
            <i className={`fa-light fa-chevron-left`} style={{
                fontSize: '1em',
                paddingRight: '.3em',
            }} /><label>Voltar</label>
        </label>



    return (<header className="Header d-flex w-100 justify-content-center align-items-center flex-column pt-2 ps-4 pe-4 pb-1 overflow-hidden" style={{
        backgroundColor: props.bg ? props.bg + ' !important' : 'red !important'
    }}>
        <div className={`background ${props.small ? 'small_background' : ''}`} />

        <img src={`https://i.imgur.com/jt0Ddaj.png`}
            className="logo"
            alt="logo"
            onClick={() => {
                if (location.pathname !== '/user') navigate('/user')
            }} />

        {goBack}
        {logout}

        {SelectRegion}


        <div className={`scheduling w-100 rounded ${props.small ? 'mt-3' : ''}`}
            onClick={() => {
                if (props.url)
                    navigate(props.url)
            }}>
            <div className="w-100 ms-3 me-3 mt-3 mb-3 d-flex align-items-center">
                <i className={`fa-light fa-${props.icon}`} style={{
                    fontSize: '3em',
                    paddingLeft: '.3em',
                }} />

                <Label className="form-label text-center fw-bold form-label me-4 pe-4 w-100 text-center"
                    style={{
                        fontSize: '16px',
                    }}>{props.text}</Label>
            </div>
        </div>
    </header >)
}