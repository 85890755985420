import { useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import { createSearchParams, Link, Navigate } from "react-router-dom";

import { Collapse, FormGroup, Label, Input } from 'reactstrap';

import { API } from '../../utils/api';

import './FAQ_Chat.css';

function formatTime(datetime) {
    datetime = new Date(datetime);
    datetime.setHours(datetime.getHours() + 3);

    return `${datetime.getDate() < 10 ? '0' : ''}${datetime.getDate()}/${datetime.getMonth() + 1 < 10 ? '0' : ''}${datetime.getMonth() + 1}/${datetime.getFullYear()} ${datetime.getHours() < 10 ? '0' : ''}${datetime.getHours()}:${datetime.getMinutes() < 10 ? '0' : ''}${datetime.getMinutes()}:${datetime.getSeconds() < 10 ? '0' : ''}${datetime.getSeconds()}`;
}

function buildMessage(message) {

    return (
        <div className='w-100 mb-2'>
            <div className={`d-flex flex-column text-light rounded p-1`}
                style={{
                    backgroundColor: message.is_reply ? '#858585' : '#b5b5b5'
                }}>
                <div class="d-flex mb-1">
                    <img src={message.employee_name ? "https://i.imgur.com/CGneyGO.png" : window.user.logo} alt="" width={42} height={42} className="bg-white rounded" />

                    <span class="d-flex flex-column ms-2">
                        <span className='small'>{message.employee_name || (message.name || window.user.name)}</span>
                        <span className='small'> {formatTime(message.date)}</span>
                    </span>
                </div>
                <label style={{ whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: message.content }} />
            </div>
        </div>
    )
}

export default function FAQ_Chat(props) {
    const divRef = useRef(null);
    const writerRef = useRef(null);

    const [infos, setInfos] = useState(false);
    const [messages, setMessages] = useState(false);
    const [writer_text, setWriterText] = useState("")

    if (!global.user)
        global.user = {};

    let user = localStorage.getItem("user");
    if (!user)
        return <Navigate to={{
            pathname: "/user/login",
            search: createSearchParams({ page: '/user/service/faq' }).toString()
        }} />

    let first = true;

    let ticket_id = new URLSearchParams(window.location.search).get('id');
    if (!ticket_id)
        return <Navigate to={{
            pathname: "/user",
        }} />


    if (!messages && first) {
        first = false;

        API("ticket/get_messages", {
            method: "POST",
            data: {
                id: ticket_id
            }
        }).then(async result => {
            if (result.result) {
                setInfos(result.infos)
                setMessages(result.messages)

                setTimeout(() => {
                    divRef.current.scrollTo(0, divRef.current.scrollHeight);
                }, 150)
            }
        })
    }

    let message_items = [];
    if (messages)
        messages.forEach(message => {
            message_items.push(buildMessage(message))
        })

    function sendReply(text) {
        writerRef.current.style.height = '0px'

        API("ticket/send_message", {
            method: "POST",
            data: {
                id: ticket_id,
                is_reply: 0,
                content: text
            }
        })

        let time = new Date();
        time.setHours(time.getHours() - 3)

        setMessages([...messages, {
            avatar: window.user.logo,
            name: window.user.name,
            content: text,
            date: time.toString(),
            employe_seen: 0,
            is_reply: 0,
            seen: 1
        }]);

        setTimeout(() => {
            divRef.current.scrollTo(0, divRef.current.scrollHeight);
        }, 150)
    }

    return (
        <>
            <div className="faq_container">
                <div className='' style={{
                    overflow: 'scroll',
                    height: '100vh',
                    overflowX: 'hidden',
                    overflowY: 'hidden',
                    width: '100vw',
                }}>

                    <div className='header w-100 text-center pt-5 pb-2'>
                        <h6 className="text-center d-block text-light position-relative" style={{
                            top: '-.3em'
                        }}>Suporte Exatta <i class="fa-light fa-headset"></i></h6>

                        <label className="text-light position-absolute d-flex flex-row align-items-center justify-content-center justify-items-center p-2" style={{
                            zIndex: 1,
                            top: '2.2em',
                            left: 1
                        }}
                            onClick={() => {
                                global.history.go(-1)
                            }}>
                            <i className={`fa-light fa-chevron-left`} style={{
                                fontSize: '1em',
                                paddingRight: '.3em',
                            }} /><label>Voltar</label>
                        </label>
                    </div>

                    <div className='d-flex flex-column' style={{
                        height: 'calc(100vh - 83px)',
                        //background: 'linear-gradient(rgb(255 255 255 / 79%), rgb(255 255 255 / 85%)) 0% 0% / cover, url(https://i.imgur.com/CyJHKLz.png) center center no-repeat',
                        //backgroundSize: 'cover'
                    }}>

                        <div className='messages d-flex flex-column h-100  pt-2 ps-2 pe-2' style={{
                            overflowY: 'scroll',
                        }} ref={divRef}>
                            {message_items}
                        </div>

                        <div className={"writter flex-column pt-1 pb-3 ps-2 pe-2 " + (infos && infos.ticket.status != 2 ? 'd-flex' : 'd-none')}>
                            <textarea ref={writerRef} className="form-control"
                                placeholder='Escreva aqui...'
                                onKeyDown={(event) => {
                                    if (event.target.scrollHeight < 300) {
                                        writerRef.current.style.height = '0px'
                                        writerRef.current.style.height = event.target.scrollHeight + 'px'
                                    }
                                }}
                                onChange={(event) => {
                                    setWriterText(event.target.value)
                                }}
                                value={writer_text}
                                style={{
                                    height: '2em'
                                }}></textarea>

                            <div className='d-flex align-items-center justify-content-center gap-2 pt-2'>
                                <button className="d-flex align-items-center justify-content-center btn btn-sm btn-success small mt-0" style={{
                                    height: '2em',
                                }} onClick={() => {
                                    //  alert(writer_text)
                                    sendReply(writer_text)
                                    setWriterText("")
                                }}>
                                    Enviar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
