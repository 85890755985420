import { useState } from 'react'
import {
    Form,
    FormGroup,
    Label,
    Input
} from 'reactstrap';
import './Reset.css';

import { Navigate, useNavigate, useLocation } from "react-router-dom";

import * as Spinner from 'react-loader-spinner'

import { API } from '../../utils/api';


function getParams(location, param = false) {
    let params = new URLSearchParams(location.search)
    return param ? params.get(param) : params;
}

function maskCode(code) {
    code = code.replace(/\D/g, "")
    code = code.replace(/(\d{3})(\d)/, "$1-$2")
    return code.substring(0, 7);
}

export default function Reset() {
    const navigate = useNavigate()

    if (getParams(useLocation(), 'home')) {

    }

    const [user, setUser] = useState(global.auth_user || '');
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    const [processing, setProcessing] = useState(false);

    let processing_sping = <label><Spinner.Oval
        height="24"
        width="24"
        color="white"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
    /></label>;

    if (localStorage.getItem("user"))
        return <Navigate to="/user" />

    function submit_reset() {
        if (processing)
            return;

        /*
    if (code === '')
        alert('code')
    else if (password === '')
        alert('password')
    else if (password2 === '')
        alert('password2')
        */

        if (code === '' || password === '' || password2 === '')
            return global.Toast.fire({
                icon: 'error',
                title: 'Preencha todos os campos',
                position: 'center'
            })

        setProcessing(true)
        API('user/reset', {
            method: 'POST',
            data: {
                code,
                password
            }
        }).then(res => {
            let title;
            setProcessing(false);

            if (res.result) {
                global.Swal.fire({
                    icon: 'success',
                    title: 'Sucesso',
                    html: 'Sua senha foi alterada com sucesso!'
                })

                navigate('/user/login');

            } else {
                switch (res.context) {
                    case 'Company Not Found':
                        title = "CNPJ não encontrado!";
                        break;
                    case 'Code Invalid':
                        title = "Código inválido ou expirado!";
                        break;
                    case 'Mail Failed':
                        title = "Tente mais tarde";
                        break;
                    default:
                        title = `Informe ao suporte: ${res.context}`;
                        break;
                }

                global.Toast.fire({
                    icon: 'error',
                    title
                })
            }
        })
    }

    function handleEnter(event) {

        if (event.key === "Enter") {
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            if (user !== "" && password !== "") {
                form[0].blur();
                form[1].blur();
                submit_reset();
            } else {
                if (index === 0) {
                    form[index + 1].focus();
                } else {
                    if (user === "" && password !== "")
                        form[index - 1].focus();
                }
            }

            event.preventDefault();
        }
    }

    return (
        <div className="reset_container d-flex align-items-center justify-content-center flex-column">
            <img src={`https://i.imgur.com/MBoNRPa.png`} className="logo" alt="logo" />
            <div className="form pt-4 w-100 ps-5 pe-5">
                <Form className="form">
                    <FormGroup className='col-12 mb-3' floating>
                        <Input type='text' className={code ? code.length >= 6 ? 'border-success' : 'border-danger' : ''}
                            id="code"
                            value={code}
                            placeholder="Informe o código"
                            maxLength={7}
                            disabled={processing}
                            onChange={event => {
                                if (event.target.value.length > 7)
                                    return;

                                setCode(maskCode(event.target.value))
                            }} />

                        <Label for="code" className={[code ? code.length >= 6 ? 'text-success' : 'text-danger' : '']}>
                            Informe o código
                        </Label>
                    </FormGroup>

                    <FormGroup className='col-12 mb-3' floating>
                        <Input type='password' className={password ? password.length >= 6 && password === password2 ? 'border-success' : 'border-danger' : ''}
                            id="password"
                            value={password}
                            placeholder="Informe sua senha"
                            minLength={6}
                            maxLength={32}
                            onKeyDown={(e) => handleEnter(e)}
                            disabled={processing}
                            onChange={event => {
                                setPassword(event.target.value)
                            }} />

                        <Label for="password" className={[password ? password.length >= 6 && password === password2 ? 'text-success' : 'text-danger' : '']}>Informe a nova senha</Label>
                    </FormGroup>

                    <FormGroup className='col-12 mb-3' floating>
                        <Input type='password' className={password ? password.length >= 6 && password === password2 ? 'border-success' : 'border-danger' : ''}
                            id="password2"
                            value={password2}
                            placeholder="Informe sua senha"
                            minLength={6}
                            maxLength={32}
                            onKeyDown={(e) => handleEnter(e)}
                            disabled={processing}
                            onChange={event => {
                                setPassword2(event.target.value)
                            }} />

                        <Label for="password2" className={[password ? password.length >= 6 && password === password2 ? 'text-success' : 'text-danger' : '']}>
                            Confirme a senha
                        </Label>
                    </FormGroup>

                    <div
                        className='btn btn-success submit mt-3 text-light form-control d-flex align-items-center justify-content-center justify-item-center'
                        type='button'
                        disabled={processing}
                        onClick={async () => {
                            submit_reset();
                        }}
                    >{processing ? processing_sping : 'Salvar'}</div>

                </Form>
            </div>
        </div >
    );
}