import { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { createSearchParams, Link, Navigate } from "react-router-dom";

import { Collapse, FormGroup, Label, Input } from 'reactstrap';

import { API } from '../../utils/api';

import './Profile.css';

export default function Profile(props) {
    if (!global.user)
        global.user = {};

    let user = localStorage.getItem("user");
    if (!user)
        return <Navigate to={{
            pathname: "/user/login",
            search: createSearchParams({ page: '/user/profile' }).toString()
        }} />


    return (
        <>
            <div className="profile_container">
                <div className='ps-3 pe-3 pt-5' style={{
                    overflow: 'scroll',
                    height: '100vh',
                    overflowX: 'hidden',
                    overflowY: 'hidden',
                    width: '100vw',
                }}>

                    <div className='header w-100 text-center'>
                        <label className="position-absolute d-flex flex-row align-items-center justify-content-center justify-items-center p-2" style={{
                            zIndex: 1,
                            color: 'black',
                            top: '2.2em',
                            left: 1
                        }}
                            onClick={() => {
                                global.history.go(-1)
                            }}>
                            <i className={`fa-light fa-chevron-left`} style={{
                                fontSize: '1em',
                                paddingRight: '.3em',
                            }} /><label>Voltar</label>
                        </label>

                        <img src={global.user.logo} className="rounded-circle" alt="" style={{
                            height: '6em',
                            width: '6em',
                            objectFit: 'contain',
                            border: 'solid 2px black',
                        }} />

                        <h6 className="text-center d-block text-secondary pt-3 fw-bold">{global.user.name}</h6>
                    </div>
                    <hr />
                    <div className='body d-flex flex-column align-items-center justify-content-center h-50'>
                        <div className="d-flex flex-column align-items-center">
                            <span className='text-danger pb-4' onClick={() =>
                                global.Swal.fire({
                                    title: <span>ATENÇÃO</span>,
                                    html: "Deletar sua conta não encerra seu contrato conosco!<br/><br/>Mais informações ligue: <br/><b>0800 000 0201</b>",
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: 'gray',
                                    cancelButtonText: 'Fechar',
                                    confirmButtonText: 'Sim, encerrar conta'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        API("user/delete", {
                                            method: "POST",
                                            data: {
                                            }
                                        }).then(async result => {
                                            if (result)
                                                global.Toast.fire({
                                                    icon: 'success',
                                                    title: 'Conta encerrada com sucesso',
                                                    position: 'center'
                                                })
                                        })

                                        global.Logout()
                                    }
                                })
                            }>Encerrar conta</span>


                            <button className='btn btn-danger' onClick={() =>
                                global.Swal.fire({
                                    title: <span>SAIR?</span>,
                                    text: "Ao sair, será preciso informar suas credenciais novamente!",
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    cancelButtonText: 'Não',
                                    confirmButtonText: 'Sim, quero sair'
                                }).then((result) => {
                                    if (result.isConfirmed)
                                        global.Logout()
                                })
                            }>Sair da conta</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
