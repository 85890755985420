import { createSearchParams, Link, Navigate } from "react-router-dom";

import './User.css'

import Header from './../../components/header'


function UserServicesList() {
    let services = [{
        icon: 'prescription-bottle-medical',
        title: 'Exames',
        address: 'exames',
    }]

    let services_react = [];
    services.forEach((item, index) => {
        services_react.push(
            <Link
                key={index}
                className="service d-flex flex-column p-5 pt-4 pb-4 text-center align-items-center justify-content-center rounded me-2"
                to={`/user/service/${item.address}`}>
                {[
                    item.icon ? <i className={`fa-light fa-${item.icon} pb-2`}></i> : '',
                    item.title
                ]}
            </Link>
        )
    })

    return (
        <div className="user_services ps-4">
            <label className="title fw-bold pb-1">Serviços Exatta Bahia</label>
            <div className="d-flex scroll">
                {services_react}
            </div>
        </div>
    )
}

function OtherServicesList() {
    let services = [{
        icon: 'clipboard-list-check',
        title: 'Orçamento eSocial',
        address: 'orcamento_esocial'
    }, {
        icon: 'user-nurse-hair-long',
        title: 'Agendamento de Exames',
        address: 'agendamento_exame'
    }, {
        icon: 'ballot-check',
        title: 'Recibo de tranmissão SST',
        type: 'alert',
        alertTitle: 'Atenção',
        alertContent: <>
            Para acessar todos os recibos da sua empresa, <br />basta <a href="https://sistema.soc.com.br/WebSoc" target="_blank" rel="noopener noreferrer">acessar o sistema SOC</a>
            <br />
            ou
            <br />
            Para <b>receber agora</b> o seu recibo via <b>WhatsApp</b>, <a href="https://wa.me/+5571997019743" target="_blank" rel="noopener noreferrer">Clique aqui!</a>
        </>
    }, {
        icon: 'file-arrow-up',
        title: 'Anexar ASO',
        address: 'aso'
    }, {
        icon: 'comments-question-check',
        title: 'Suporte Exatta',
        address: 'faq'
    }]

    let services_react = [];
    services.forEach((item, index) => {
        let link;
        if (item.type === 'alert') {
            link = <div
                key={index}
                className="btn border-0 text-success service d-flex flex-column p-5 pt-4 pb-4 fw-bold text-center align-items-center justify-content-center rounded me-2 mb-2"
                onClick={() => {
                    global.Swal.fire({
                        title: item.alertTitle,
                        html: item.alertContent,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Fechar'
                    })
                }}>
                {[
                    item.icon ? <i className={`fa-light fa-${item.icon} pb-2`}></i> : '',
                    item.title
                ]}
            </div>
        } else {
            if (item.address.startsWith('http'))
                link = <a
                    key={index}
                    className="service d-flex flex-column p-5 pt-4 pb-4 fw-bold text-center align-items-center justify-content-center rounded me-2 mb-2"
                    href={item.address}
                    target="_blank"
                    rel="noopener noreferrer">
                    {[
                        item.icon ? <i className={`fa-light fa-${item.icon} pb-2`}></i> : '',
                        item.title
                    ]}
                </a>
            else
                link = <Link
                    key={index}
                    className="service d-flex flex-column p-5 pt-4 pb-4 fw-bold text-center align-items-center justify-content-center rounded me-2 mb-2"
                    to={`/user/service/${item.address}`}>
                    {[
                        item.icon ? <i className={`fa-light fa-${item.icon} pb-2`}></i> : '',
                        item.title
                    ]}
                </Link>
        }

        services_react.push(
            link
        )
    })

    return (
        <div className="other_services ps-4 pt-3">
            <label className="title fw-bold">Centro de Atendimento</label>
            <div className="scroll pt-2 pb-2">
                {services_react}
            </div>
        </div>
    )
}


export default function User(props) {
    let user = localStorage.getItem("user");

    if (!user)
        return <Navigate to={{
            pathname: "/user/login",
        }} />

    return <div className="user_container">
        <Header icon="calendar-circle-plus" text="Faça aqui o seu agendamento online" url="service/agendamento_exame" small={true} bg="#F2F6F9" />
        {/* <UserServicesList /> */}
        <div style={{
            overflow: 'scroll',
            height: 'calc(100vh - 17em)',
            overflowX: 'hidden',
            width: '100vw'
        }}>
            <OtherServicesList />
        </div>

    </div >;
}