import { FormGroup, Label, Input } from 'reactstrap';

function maskCNPJ(cnpj) {
    let buf = cnpj.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
    buf = !buf[2] ? buf[1] : buf[1] + '.' + buf[2] + '.' + buf[3] + '/' + buf[4] + (buf[5] ? '-' + buf[5] : '');
    return (buf);
}

function validateCNPJ(cnpj) {
    var b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
    var c = String(cnpj).replace(/[^\d]/g, '')

    if (c.length !== 14)
        return false

    if (/0{14}/.test(c))
        return false

    for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
    if (c[12] != (((n %= 11) < 2) ? 0 : 11 - n))
        return false

    for (i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
    if (c[13] != (((n %= 11) < 2) ? 0 : 11 - n))
        return false

    return true
}

let buf_value = '';
export default function CNPJInput(props) {

    if (buf_value != props.cnpj) {
        props.setCNPJ(maskCNPJ(props.cnpj.replace(/\D/g, '')))
        buf_value = maskCNPJ(props.cnpj.replace(/\D/g, ''))
    }

    return (<FormGroup className='col-12 mb-3' floating>
        <Input type='text' className={props.cnpj ? validateCNPJ(props.cnpj) ? 'border-success' : 'border-danger' : ''}
            id="cnpj"
            value={props.cnpj}
            maxLength={18}
            placeholder="CNPJ da empresa"
            onKeyDown={(event) => {
                if (event.keyCode != 8)
                    return;

                let buf = event.target.value.replace(/\D/g, '').slice(0, -1);
                props.setCNPJ(buf)
                if (props.onKeyDown)
                    props.onKeyDown(event)
            }}
            disabled={props.disabled}
            onChange={(event) => {
                props.setCNPJ(maskCNPJ(event.target.value))

                if (props.onChange)
                    props.onChange(maskCNPJ(event.target.value))
            }} />

        {/*<Label for="cnpj" className={props.raw_label ? '' : 'ms-2 ' + (props.cnpj ? validateCNPJ(props.cnpj) ? ' text-success' : ' text-danger' : '')}>{props.label || 'CNPJ da empresa'}</Label>*/}
        <Label for="cnpj" className={(props.raw_label ? '' : 'ms-2 ') + (props.cnpj ? validateCNPJ(props.cnpj) ? ' text-success' : ' text-danger' : '')}>{props.label || 'CNPJ da empresa'}</Label>
    </FormGroup>)
};