import './ListExams.css'

export default function ListExame(props) {
    let list_react = [];
    props.exams.forEach((item, index) => {
        list_react.push(<div className='d-flex col-12 rounded mb-3 exam_item pt-1 pb-1' key={index} >

            <div className='col-9 d-flex flex-column'>
                <label className='fw-bold'>{item.employee}</label>
                <label className='small'>Exame: {`${item.exam}`}</label>
            </div>
            <div className='col-3'>{
                item.status === 0 ?
                    <>
                        <label className='fw-bold'>Agendado</label>
                        <br />
                        <label>{item.date}</label>
                    </> :
                    <label className='fw-bold w-100 text-end'>Realizado</label>
            }</div>
        </div>)
    })

    return (<div className='d-flex row m-1 text-light'>{list_react}</div>)
}