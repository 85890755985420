import React from 'react';
import { Nav, NavItem } from 'reactstrap';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';

import './Navigation.css';

const Navigation = (props) => {
    global.navigate = useNavigate();

    const { pathname } = useLocation();

    if (pathname == '/user/service/faq_chat')
        return (<></>)

    return (
        <nav className="navbar navbar-expand-md navbar-light fixed-bottom" role="navigation">
            <div className="container-fluid">
                <Nav className="ml-auto text-center w-100 d-flex justify-content-around">
                    <NavItem>
                        <NavLink to="/" className="nav-link">
                            <i className="fa-light fa-house"></i>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to="/user" className="nav-link">
                            <i className="fa-light fa-user"></i>
                        </NavLink>
                    </NavItem>
                </Nav>
            </div>
        </nav>
    )
};

export default Navigation;