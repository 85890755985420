import { useState } from 'react'
import {
    Form,
    FormGroup,
    Label,
    Input
} from 'reactstrap';
import './Login.css';

import { Navigate, useNavigate, useLocation, Link } from "react-router-dom";

import * as Spinner from 'react-loader-spinner'

import { API } from './../../utils/api';

import CNPJInput from './../../components/cnpjinput'


function getParams(location, param = false) {
    let params = new URLSearchParams(location.search)
    return param ? params.get(param) : params;
}

export default function Recovery() {
    const navigate = useNavigate()

    const [user, setUser] = useState(global.auth_user || '');
    const [processing, setProcessing] = useState(false);
    const [first, setFirst] = useState(true);

    let processing_sping = <label><Spinner.Oval
        height="24"
        width="24"
        color="white"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
    /></label>;

    if (localStorage.getItem("user"))
        return <Navigate to="/user" />

    function submit_recovery() {
        if (!user)
            return;

        setProcessing(true)

        API('user/recovery', {
            method: 'POST',
            data: {
                user
            }
        }).then(res => {
            let title;
            setProcessing(false);

            if (res.result) {
                switch (res.context) {
                    case 'Code Already Sent':
                        title = "Código reenviado, verifique seu email!";
                        break;
                    case 'ok':
                        title = "Código enviado com sucesso, verifique seu email!";
                        break;
                }

                global.Swal.fire({
                    icon: 'success',
                    title: 'Sucesso',
                    html: title
                })

                navigate('/user/reset_password');
            } else {
                switch (res.context) {
                    case 'Company Not Found':
                        title = "CNPJ não encontrado!";
                        break;
                    case 'Code Invalid':
                        title = "Código inválido ou expirado!";
                        break;
                    case 'Mail Failed':
                        title = "Tente mais tarde";
                        break;
                }

                global.Toast.fire({
                    icon: 'error',
                    title
                })
            }
        })
    }

    function handleEnter(event) {
        if (event.key === "Enter") {
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            if (user !== "") {
                form[0].blur();
                submit_recovery();
            }
            event.preventDefault();
        }
    }

    if (first) {
        setFirst(false)
        global.Swal.fire({
            icon: 'info',
            title: 'Super dica',
            html: <label>Já possui o código, então é só clicar na opção <b>Recebi o código</b>!</label>
        })
    }

    return (
        <div className="login_container d-flex align-items-center justify-content-center flex-column">
            <img src={`${process.env.PUBLIC_URL + "/assets/logo_exatta.png"}`} className="logo" alt="logo" />
            <div className="form pt-4 w-100 ps-5 pe-5">
                <Form className="form">
                    <CNPJInput
                        cnpj={user}
                        setCNPJ={setUser}
                        label="Informe seu CNPJ"
                        onKeyDown={(e) => handleEnter(e)}
                        disabled={processing}
                        raw_label={true}
                        onChange={event => {
                            setUser(event.target.value)
                        }} />

                    <div
                        className='submit mt-3 text-light form-control d-flex align-items-center justify-content-center justify-item-center'
                        type='button'
                        disabled={processing}
                        onClick={async () => {
                            submit_recovery();
                        }}
                    >{processing ? processing_sping : 'Receber código'}</div>

                </Form>

                <div className='w-100 mt-3 d-flex text-center justify-content-center flex-column'>
                    <Link className='text-light text-center text-decoration-none' to={"/user/reset_password"}>Recebi o código</Link>
                </div>
            </div>
        </div >
    );
}