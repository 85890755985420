import { useState } from 'react'
import {
    Form,
    FormGroup,
    Label,
    Input
} from 'reactstrap';
import './Login.css';

import { useNavigate, useLocation } from "react-router-dom";
import { /*createSearchParams,*/ Link, Navigate } from "react-router-dom";

import * as Spinner from 'react-loader-spinner'

import { Auth } from './../../utils/auth';

import CNPJInput from './../../components/cnpjinput'


function getParams(location, param = false) {
    let params = new URLSearchParams(location.search)
    return param ? params.get(param) : params;
}

export default function Login() {
    const navigate = useNavigate()
    const location = useLocation()

    const [user, setUser] = useState(global.auth_user || '');
    const [password, setPassword] = useState(global.auth_password || '');
    const [processing, setProcessing] = useState(global.auth_processing);

    global.auth_user = user;

    let processing_sping = <label><Spinner.Oval
        height="24"
        width="24"
        color="white"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
    /></label>;

    if (localStorage.getItem("user"))
        return <Navigate to="/user" />

    function submit_login() {
        if (processing)
            return;
        if (user === '' || password === '')
            return global.Toast.fire({
                icon: 'error',
                title: 'Preencha todos os campos',
                position: 'center'
            })

        setProcessing(true)
        global.auth_processing = true;

        Auth({
            user: user.replace(/\D/g, ''),
            password
        }).then(result => {
            if (result) {

                global.Toast.fire({
                    icon: 'success',
                    title: 'Logado com sucesso',
                })

                setTimeout(() => {
                    global.Swal.fire({
                        html: <span>
                            Olá, <b>{global.user.name}</b>, você está acessando a nova plataforma da <b>Exatta Bahia</b><br /><br />
                            Aqui você terá acesso à diversos dos nossos serviços!</span>,
                        iconHtml: <img src={global.user.logo} alt="" style={{
                            height: '100%',
                            width: '100%',
                            borderRadius: '5em',
                            objectFit: 'cover'
                        }} />,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Fechar'
                    })
                }, 2000)


                let param = getParams(location, 'page');
                navigate(param ? param : '/user')
            }
            else {
                global.Toast.fire({
                    icon: 'error',
                    title: 'Credenciais inválidas',
                    position: 'center'
                })
                setProcessing(false)
                global.auth_processing = false;
            }
        })
    }

    function handleEnter(event) {
        if (event.key === "Enter") {
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            if (user !== "" && password !== "") {
                form[0].blur();
                form[1].blur();
                submit_login();
            } else {
                if (index === 0) {
                    form[index + 1].focus();
                } else {
                    if (user === "" && password !== "")
                        form[index - 1].focus();
                }
            }

            event.preventDefault();
        }
    }

    return (
        <div className="login_container d-flex align-items-center justify-content-center flex-column">
            <img src={`https://i.imgur.com/MBoNRPa.png`} className="logo" alt="logo" />
            <div className="form pt-4 w-100 ps-5 pe-5">
                <Form className="form">
                    <CNPJInput
                        cnpj={user}
                        setCNPJ={setUser}
                        label="Informe seu CNPJ"
                        onKeyDown={(e) => handleEnter(e)}
                        disabled={processing}
                        raw_label={true} />

                    <FormGroup className='col-12 mb-3' floating>
                        <Input type='password' className={password ? password.length >= 6 ? 'border-success' : 'border-danger' : ''}
                            id="password"
                            value={password}
                            placeholder="Informe sua senha"
                            minLength={6}
                            maxLength={32}
                            onKeyDown={(e) => handleEnter(e)}
                            disabled={processing}
                            onChange={event => {
                                setPassword(event.target.value)
                                global.auth_password = event.target.value;
                            }} />

                        <Label for="password" className={password ? password.length >= 6 ? 'text-success' : 'text-danger' : ''}>Informe sua senha</Label>
                    </FormGroup>

                    <div
                        className='submit mt-3 text-light form-control d-flex align-items-center justify-content-center justify-item-center'
                        type='button'
                        disabled={processing}
                        onClick={async () => {
                            submit_login();
                        }}
                    >{processing ? processing_sping : 'Entrar'}</div>

                </Form>

                <div className='w-100 mt-3 d-flex text-center justify-content-center flex-column'>
                    <Link className='text-light text-center text-decoration-none' to={"/user/recovery_password"}>Criar ou redefinir senha</Link>
                    <br />
                    <Link className='text-light text-center text-decoration-none' to={"/user/register"}>Criar conta</Link>
                </div>
            </div>
        </div >
    );
}