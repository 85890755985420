import { FormGroup, Label, Input } from 'reactstrap';

function validateEmail(email) {
    let valid = email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    return valid;
};

export default function EmailInput(props) {
    return (<FormGroup className='col-12 mb-3' floating>
        <Input type='text' className={props.email ? validateEmail(props.email) ? 'border-success' : 'border-danger' : ''}
            id="email"
            value={props.email}
            maxLength={64}
            placeholder="Email de contato"
            disabled={props.disabled}
            onChange={event => {
                props.setEmail(event.target.value)
            }} />

        <Label for="email" className={'ms-2 ' + (props.email ? validateEmail(props.email) ? 'text-success' : 'text-danger' : '')}>{props.label || 'Email de contato'}</Label>
    </FormGroup>)
};