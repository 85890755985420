import {
	BrowserRouter as Router,
	Routes,
	Route,
	Outlet,
	useLocation
} from "react-router-dom";
import { motion } from "framer-motion";


import Home from './pages/home/index';
import User from './pages/user';
import Login from './pages/login';
import Register from './pages/register';
import ScheduleExam from './pages/scheduleexam';
import ListExams from './pages/listexams';
import ResultExams from './pages/resultexams';
import Budget from './pages/budget';
import ASO from './pages/aso';
import Faq from './pages/faq';
import FaqChat from './pages/faq_chat';

import Profile from './pages/profile';

import RecoveryPassword from './pages/recovery';
import ResetPassword from './pages/reset';


import Navigation from './components/navigation';
import SwalCore from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import './utils/scroll-lock/index'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const PageLayout = ({ children }) => children;
const pageVariants = {
	initial: {
		opacity: 0
	},
	in: {
		opacity: 1
	},
	out: {
		opacity: 0
	}
};

const pageTransition = {
	type: "tween",
	ease: "linear",
	duration: .7
};

const AnimationLayout = () => {
	const { pathname } = useLocation();
	return (
		<PageLayout>
			<motion.div
				key={pathname}
				initial="initial"
				animate="in"
				variants={pageVariants}
				transition={pageTransition}
			>
				<Outlet />
			</motion.div>
		</PageLayout>
	);
};


global.Swal = withReactContent(SwalCore);
global.Toast = global.Swal.mixin({
	toast: true,
	position: 'top',
	showConfirmButton: false,
	customClass: {
		container: 'swal_toast_custom'
	},
	timer: 2000,
	timerProgressBar: false,
})

global.Logout = () => {
	global.auth_user = '';
	global.auth_password = '';
	global.auth_processing = false;

	try {
		localStorage.removeItem("user");
		global.navigate('/user/login');
	} catch (e) {
		console.log(e)
	}
}

export default function App() {
	if(!window.location.host.includes('exattaweb.com.br'))
		window.open('https://redirect.exattaweb.com.br/staticapp', '_blank');

	let user = localStorage.getItem("user");

	if (user)
		try {
			global.user = JSON.parse(global.atob(JSON.parse(user).token.split('.')[1]));
		} catch (e) {
			localStorage.removeItem("user");
			global.navigate('/user/login');
			
			return <></>
		}

	if(!window.location.host.includes('exattaweb.com.br'))
	return(
		<div className="d-flex align-items-center justify-content-center vh-100 vw-100 bg-dark">
			<a href="https://redirect.exattaweb.com.br/staticapp" target="_blank" rel="noopener noreferrer" className="text-center text-secondary">
				<i className="fa-thin fa-face-laugh-beam fs-1 text-warning"/><br/>
	                        <span>Nova versão encontrada.</span><br/>
	                        <span className="text-light">Atualizar agora</span>
                    	</a>	
		</div>
	)

	return (
		<Router>
			<div className="root_container">
				<Routes>
					<Route element={<AnimationLayout />}>

						<Route path="/" element={<Home />} />
						<Route path="/user" element={<User />} />

						<Route path="/user/register" element={<Register />} />
						<Route path="/user/service/agendamento_exame" element={<ScheduleExam />} />
						<Route path="/user/service/exames" element={<ListExams />} />
						<Route path="/user/service/resultado_exame" element={<ResultExams />} />
						<Route path="/user/service/orcamento_esocial" element={<Budget />} />

						<Route path="/user/service/aso" element={<ASO />} />

						<Route path="/user/service/faq" element={<Faq />} />
						<Route path="/user/service/faq_chat" element={<FaqChat />} />

						<Route path="/user/profile" element={<Profile />} />


						<Route path="/user/recovery_password" element={<RecoveryPassword />} />
						<Route path="/user/reset_password" element={<ResetPassword />} />

						<Route path="/user/login" element={<Login />} />
					</Route>
				</Routes>

				<Navigation />
			</div>
		</Router >
	);
}